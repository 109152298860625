/* eslint-disable */
import React, { useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import Modal from "../../components/ui/Modal";
import TextField from "../../components/ui/TextField";
import {
  CancellationBanner,
  CloseIcon,
  Kycapproved,
  KycUnsuccessful,
  SearchIcon,
} from "../../assets/Icons";
import CheckboxTick from "../../assets/svg/Checkbox.svg";
import Radio from "../../components/ui/Radio";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import ISendMoney from "./sendMoney";
import Cross from '../../assets/svg/Cross.svg';
// import Tooltip from "../../components/ui/Tooltip";
import mixpanel from "mixpanel-browser";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { isInwardTransaction, transactionDetails } from "../../store";
import { purposeCodeListInward } from "../../utils";
import { handleTransactionComplete } from "../../services/transaction";
import { CloseIconModal } from "../../assets/Icons";
import { CurrenciesDetails } from "../../store/store";

const Modals: React.FC<ISendMoney.IModalsProps> = ({
  isModalOpen,
  sendingCurrencyModalHeader,
  receiveCurrencyModalHeader,
  setIsModalOpen,
  setSearchInput,
  searchInput,
  sendCurrencyModalData,
  sendingCurrency,
  setCurrency,
  selectPurposeHeader,
  selectedInwardPurposeCode,
  setSelectedInwardPurposeCode,
  purposeCodeList,
  setSelectedPurposeCode,
  setPromoCodeApplied,
  selectedPurposeCode,
  setConfirmedPurpose,
  offerHeader,
  offerSearchInput,
  setOfferSearchInput,
  // offerData,
  // selectedOfferCode,
  // setSelectedOfferCode,
  TransactioncancellationReasons,
  setCancellationReasons,
  cancellationReasons,
  setOtherReason,
  otherReason,
  isTcsPresent,
  setIsLoanSanctionLetter,
  activeCoins,
  useCoins,
  // setUseCoins,
  setUsable,
  handleCustomPromo,
  setHopCoins,
  enteredCode,
  setEnteredCode,
  // setPromoCodeError,
  promoCodeApplied,
  isInward,
  setIsInward,
  kycStatus,
  setKycStatus,
  setNoLoanConfirmationLetter,
  loading,
}) => {
  const navigate = useNavigate();
  const transactionData = useRecoilValue(transactionDetails);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  const [enteredCoins, setEnteredCoins] = React.useState(0);
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const hopInto2 = transactionData?.hop_fees * 2;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const specialCharRegExp = "[a-zA-Z0-9 ]{1,}";
  const getSelectedPurpose = (purposeCode: any) => {
    if (isInward) {
      return purposeCodeListInward.filter((data) => data.value === purposeCode)[0];
    } else {
      return purposeCodeList.filter((data) => data.value === purposeCode)[0];
    }
  };

  useEffect(() => {
    if (useCoins > activeCoins) {
      setUsable(true);
    } else {
      setUsable(false);
    }
  }, [useCoins]);

  useEffect(() => {
    const time = setTimeout(() => {
      if (enteredCoins > 0) {
        setHopCoins(enteredCoins);
      }
    }, 300);
    return () => clearTimeout(time);
  }, [enteredCoins]);

  useEffect(() => {
    const time = setTimeout(() => {
      if (enteredCoins > 0) {
        setHopCoins(enteredCoins);
      }
    }, 300);
    return () => clearTimeout(time);
  }, [enteredCoins]);

  useEffect(() => {
    if (sendingCurrency == "GBP") setIsInward(true);
    else if (sendingCurrency == "INR") setIsInward(false);
  }, [sendingCurrency, isInward]);

  useEffect(() => {
    if (activeCoins === 0) {
      setEnteredCoins(0);
      setHopCoins(0);
    } else if (activeCoins > 0 && activeCoins > hopInto2) {
      setEnteredCoins(hopInto2);
      setHopCoins(hopInto2);
    } else if (activeCoins > 0 && activeCoins < hopInto2) {
      setEnteredCoins(activeCoins);
      setHopCoins(activeCoins);
    }
  }, [activeCoins, hopInto2]);


  const enabledButtonClassRedeem =
    "px-5 lg:px-12 lg:py-3 py-1 w-40 h-12 font-bold text-white transition-all border-2 rounded-full bg-primary font-ssp border-primary";
  const enabledButtonClassApply =
    "px-5 lg:px-8 lg:py-3 py-1 min-w-fit w-40 h-12 font-bold text-white transition-all border-2 rounded-full bg-primary font-ssp border-primary";
  // const disabledButtonClass =
  //   "px-5 lg:px-16 lg:py-3 py-1 font-bold text-white transition-all border-2 rounded-full bg-primary font-ssp hover:bg-gray-500 cursor-not-allowed border-primary";

  const bgColor = isInward
    ? selectedInwardPurposeCode !== ""
      ? "bg-primary"
      : "bg-grey-350"
    : selectedPurposeCode !== ""
      ? "bg-primary"
      : "bg-grey-350";

  const handleNavigate = () => {
    handleTransactionComplete();
    resetTransactionDetails();
    navigate("/");
  };

  const messages = ["Promo code applied successfully..", "Invalid promo code"];

  return (
    <div>
      {/* Base-Destination Country Select Modal */}
      <Modal
        header={
          isModalOpen === "sendCurrency" ? sendingCurrencyModalHeader : receiveCurrencyModalHeader
        }
        headerClass={"bg-primary !my-0 py-6 pr-6 lg:pl-12 pl-[30px]"}
        modalClass="!p-0 !rounded-t-[10px] lg:overflow-hidden overflow-y-scroll !w-full lg:!w-auto lg:mb-20 max-h-[90%] lg:max-h-full"
        visible={isModalOpen === "sendCurrency" || isModalOpen === "receiveCurrency"}
        closeHandler={() => {
          setIsModalOpen("");
          setSearchInput("");
        }}
      >
        <div className="px-6 pt-4 lg:px-9 group">
          <div className="h-12 lg:border-none w-full flex justify-center lg:w-[404px] mb-9">
            <TextField
              name="searchValue"
              type="text"
              placeholder={
                <div className="flex items-center justify-between gap-2 mt-5">
                  <span>Search by country or currency</span>
                  <SearchIcon fillColor="fill-primary" />
                </div>
              }
              value={searchInput}
              fullWidth
              inputClasses="lg:!rounded-md h-12 !pt-1 bg-primary-100 w-full"
              placeholderStyle={clsx(
                searchInput && "hidden",
                "peer-placeholder-shown:!top-4 text-sm text-gray-600 peer-focus:hidden w-full px-6 h-full",
              )}
              onChange={(e) => setSearchInput(e.target.value)}
              pattern={specialCharRegExp}
            />
          </div>
          <div className="lg:mx-[68px] overflow-y-auto">
            {sendCurrencyModalData?.length === 0 ? (
              <div className="pb-64 text-xl font-normal text-center text-primary-650">
                {`"${searchInput}" cannot be found`}
              </div>
            ) : (
              sendCurrencyModalData.map((data, index) => {
                return (
                  <div
                    className="flex items-center justify-between w-full py-6 border-b cursor-pointer lg:mb-11 lg:border-none lg:py-0 border-grey-1000"
                    key={index}
                    onClick={() => {
                      isModalOpen === "sendCurrency"
                        ? data.currencyName === "INR"
                          ? (setCurrency((prev: CurrenciesDetails) => ({
                            ...prev,
                            receiveCurrency: "USD",
                          })),
                            setCurrency((prev: CurrenciesDetails) => ({
                              ...prev,
                              sendingCurrency: data.currencyName,
                            })),
                            mixpanel.track(`Currency selected_yousend: ${data.currencyName}`))
                          : (setCurrency((prev: CurrenciesDetails) => ({
                            ...prev,
                            sendingCurrency: data.currencyName,
                          })),
                            mixpanel.track(`Currency selected_yousend: ${data.currencyName}`))
                        : data.currencyName === "INR"
                          ? (setCurrency((prev: CurrenciesDetails) => ({
                            ...prev,
                            sendingCurrency: "USD",
                          })),
                            setCurrency((prev: CurrenciesDetails) => ({
                              ...prev,
                              receiveCurrency: data.currencyName,
                            })),
                            mixpanel.track(`Currency selected_theyrecieve : ${data.currencyName}`))
                          : (setCurrency((prev: CurrenciesDetails) => ({
                            ...prev,
                            receiveCurrency: data.currencyName,
                          })),
                            mixpanel.track(`Currency selected_theyrecieve : ${data.currencyName}`));
                      setIsModalOpen("");
                    }}
                  >
                    <div className="mr-8 text-xl font-normal text-primary-650">
                      {data.countryName}
                      {/* {console.log(data)} */}
                    </div>
                    <div className="flex items-center w-2/6">
                      <div className={clsx("w-8 h-8 flex justify-center items-center mr-5")}>
                        <img
                          src={`/images/png/${data.currencyName}.png`}
                          alt="country-image"
                          className="rounded-[200px] border-[2.5px] border-gray-300 w-full h-full"
                        />
                      </div>
                      <div className="text-xl font-bold text-left text-primary-650 justify-items-start">
                        {data.currencyName}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Modal>
      {/* Select Purpose Modal */}
      <Modal
        header={selectPurposeHeader}
        headerClass={"bg-primary !my-0 py-6 pr-6 pl-10"}
        modalClass="!p-0 !rounded-t-[10px] overflow-hidden bottom-[500px] lg:!w-auto"
        visible={isModalOpen === "selectPurpose"}
        closeHandler={() => {
          // setSelectedPurposeCode("");
          // setSelectedInwardPurposeCode("");
          setIsModalOpen("");
        }}
      >
        <div className="flex flex-col items-center justify-between ml-10 lg:px-11 pb-9">
          <div className="relative">
            <Radio
              // numberOfRadioButtons={4}
              numberOfRadioButtons={2}
              name={"purpose"}
              value={purposeCodeList.map(({ value }) => value)}
              onChangeHandler={(e) => {
                const selectedValue = e.target.value;
                mixpanel.track(`Purpose Code: ${selectedValue}`);
                isInward
                  ? setSelectedInwardPurposeCode(selectedValue)
                  : setSelectedPurposeCode(selectedValue);
                setIsModalOpen("");
                if (
                  selectedValue === "Overseas Education - University Fees" ||
                  selectedValue === "Overseas Education - Living Expenses"
                ) {
                  if (isTcsPresent) {
                    setIsModalOpen("showLoanConfirmation");
                  } else {
                    setConfirmedPurpose(getSelectedPurpose(selectedValue));
                  }
                } else if (selectedValue === "Family Maintenance") {
                  setIsModalOpen("showFamilyMaintenance");
                  setConfirmedPurpose(getSelectedPurpose(selectedValue));
                } else if (selectedValue === "Personal Gift or Donation") {
                  setIsModalOpen("PersonalGiftOrDonation");
                  setConfirmedPurpose(getSelectedPurpose(selectedValue));
                } else {
                  setConfirmedPurpose(getSelectedPurpose(selectedValue));
                }
              }}
              labelName={purposeCodeList.map(({ value }) => value)}
              showAsColumn
              radioClassName="mt-8"
              labelClassName="text-primary-650 font-normal text-[15px] lg:text-base md:text-xl lg:mb-[20px]ransition-all duration-200 cursor-pointer mt-8 "
            />
          </div>
        </div>
      </Modal>
      {/* Apply Promocode Modal */}
      <Modal
        header={offerHeader}
        headerClass={"!my-0 py-5 px-9 bg-primary text-white"}
        modalClass="!p-0 rounded-xl !overflow-hidden bottom-[500px]  lg:!w-auto"
        visible={isModalOpen === "offers"}
        closeHandler={() => setIsModalOpen("")}
        closable={true}
        closeButtonIcon={<CloseIconModal />}
      >
        <div className="flex flex-col justify-between pb-4 mb-10 border-t-2 mx-9 pt-4 border-grey-250 lg:mb-0">
          <div className="hidden w-full sm:w-[372px] mb-9">
            <TextField
              name="offerSearched"
              type="text"
              placeholder={
                <div className="flex items-center justify-between gap-2">
                  <span>Search promo code</span>
                  <SearchIcon fillColor="fill-primary" />
                </div>
              }
              value={offerSearchInput}
              fullWidth
              inputClasses="lg:!rounded-md h-16 !pt-1 bg-primary-100 w-full "
              placeholderStyle={clsx(
                offerSearchInput
                  ? "hidden peer-placeholder-shown:!top-4"
                  : "peer-placeholder-shown:!top-6",
                "text-sm text-gray-600 peer-focus:hidden w-full px-6",
              )}
              onChange={(e) => {
                setOfferSearchInput(e.target.value);
              }}
              pattern={specialCharRegExp}
            />
          </div>
          <div id="redeemHopCoins" className="flex flex-col space-y-2">
            <div className="flex justify-between">
              {/* <div className="flex items-center space-x-1">
                <span className="text-[13px] lg:text-[24px] font-semibold font-ssp">
                  HOP Coin Balance:
                </span>
                <span className="text-[13px] lg:text-[24px] font-semibold font-ssp">
                  {activeCoins}
                </span>
              </div> */}
              <div className="flex items-center space-x-1">
                <span className="text-[13px] lg:text-[24px] font-semibold font-ssp">
                  Apply Promo Code
                </span>
              </div>
            </div>
            <div className="flex space-x-6">
              <div
                className={`flex justify-between border items-center ${promoCodeApplied ? "border-[#16A34A]" : "border-gray-200"
                  } w-full`}
              >
                <div className="flex w-full items-center justify-center">
                  {promoCodeApplied && (
                    <img src={CheckboxTick} alt="checkbox" className="px-2 pr-0" />
                  )}
                  <input
                    className={clsx("w-full py-1 px-2 lg:py-3 font-semibold text-lg ")}
                    placeholder="Enter the promo code"
                    // value={enteredCoins}
                    value={enteredCode}
                    onChange={(e: any) => setEnteredCode(e.target.value.toUpperCase())}
                  // onChange={(e: any) => setEnteredCoins(e.target.value)}
                  />
                </div>
                {promoCodeApplied && (
                  <button
                    onClick={() => {
                      setEnteredCode("");
                      setPromoCodeApplied(false);
                    }}
                    className="text-red-100 px-4 cursor-pointer"
                  >
                    <img src={Cross} />
                  </button>
                )}
              </div>
              {!promoCodeApplied && (
                <button onClick={handleCustomPromo} className={enabledButtonClassApply}>
                  {loading ? (
                    <div className="flex items-center justify-center animate-spin-slow">
                      <img src="/images/svg/smallLoader.svg" alt="" />
                    </div>
                  ) : (
                    "Apply"
                  )}
                </button>
              )}
              {/*               <button
                onClick={() => {
                  if (enteredCoins <= hopInto2) {
                    setHopCoins(enteredCoins);
                    setIsModalOpen("");
                  } else {
                    window.alert(`Please enter amount greater than INR 80000 to redeem HOP coins`);
                  }
                }}
                // disabled
                className={enabledButtonClassRedeem}
              >
                Apply
              </button> */}
            </div>
            {/* <div className="flex items-center mt-2 space-x-1">
              <span className="text-gray-500 text-[10px] lg:text-[16px]">
                Maximum Usable Coins:{" "}
                <span className="font-bold text-primary-200">
                  {hopInto2 >= activeCoins ? activeCoins : hopInto2}
                </span>
              </span>
            </div> */}
            {promoCodeApplied && (
              <p className="text-[#0F5BF1] font-semibold text-md">Promo Code Applied 🎉</p>
            )}
            {transactionData?.promoCodeDetails?.message === "Invalid promo code" && (
              <p className="font-semibold text-red-500 lg:mt-3">
                {transactionData?.promoCodeDetails?.message}
              </p>
            )}
          </div>
          {/* <div className="w-full mt-8 border-b-2 border-blue-300"></div>
          <div className="flex items-start p-5 mb-8 -my-2 -mx-9 lg:items-center bg-primary-200">
            <div className="mr-4 text-lg font-bold text-white w-max">Apply Promo Code</div>
          </div> */}
          <div className="flex items-center space-x-1 pt-2">
            <span className="text-[13px] lg:text-[24px] font-semibold font-ssp">
              Hop Coins Balance: {activeCoins}
            </span>
          </div>
          <div>
            <div className="w-full">
              <div
                className={`w-full flex justify-between border focus:ring-[#16A34A] ${enteredCoins > 0 ? "border-[#16A34A]" : "border-grey-250"
                  }   items-center`}
              >
                <div className="flex items-center justify-start">
                  {enteredCoins > 0 && (
                    <img src={CheckboxTick} alt="checkbox" className="px-2 pr-0" />
                  )}
                  <input
                    type="text"
                    className="w-full p-2 font-semibold text-lg focus:ring-0 focus:border-0 focus:border-transparent focus:outline-none border-transparent"
                    placeholder="Enter Code Here"
                    value={enteredCoins}
                    onChange={(e: any) => {
                      let value = e.target.value;
                      if (value === "") {
                        setEnteredCoins(0);
                        setHopCoins(0);
                      } else if (!isNaN(value) && Number(value) >= 0 && Number(value) <= hopInto2) {
                        if (value.startsWith(0) && value.length > 1) {
                          value = value.replace(/^0+/, "");
                        }
                        setEnteredCoins(value);
                      }
                    }}
                    autoComplete="off"
                  />
                </div>
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => {
                    setEnteredCoins(0);
                    setHopCoins(0);
                  }}
                >
                  <img src={Cross} />
                </div>
              </div>
              <div className="flex items-center mt-2 space-x-1">
                <span className="text-gray-500 text-[10px] lg:text-[16px]">
                  Maximum usable Hop Coins as per convenience fess -{" "}
                  <span className="font-bold">
                    {hopInto2 >= activeCoins ? activeCoins : hopInto2}
                  </span>
                </span>
              </div>
              {enteredCoins > 0 && (
                <p className="text-[#0F5BF1] font-semibold text-md">Hop Coins Applied 🎉</p>
              )}
              {/* <button onClick={handleCustomPromo} className={enabledButtonClassApply}>
                {loading ? (
                  <div className="flex items-center justify-center animate-spin-slow">
                    <img src="/images/svg/smallLoader.svg" alt="" />
                  </div>
                ) : promoCodeApplied ? (
                  `Code Applied`
                ) : (
                  "Apply Code"
                )}
              </button> */}
            </div>
            {!messages?.includes(transactionData?.promoCodeDetails?.message) && (
              <span
                className={clsx(
                  transactionData?.promoCodeDetails?.promoCodeApplied
                    ? "font-semibold text-green-500 lg:mt-3"
                    : "font-semibold text-red-500 lg:mt-3",
                )}
              >
                {transactionData?.promoCodeDetails?.message}
              </span>
            )}
            {/* <div className="flex items-center">
              <TextField name="offerSearched" type="text" placeholder="Enter custom promo code" />
              <Button
                id="dkaujii-dsjnn,-nmk"
                bgColor={"bg-primary"}
                fontStyle={{
                  fontFamily: "",
                  fontSize: "text-[10px] lg:text-lg",
                  fontWeight: "font-bold",
                  fontColor: `text-white`,
                }}
                borderStyle={{
                  borderRadius: "rounded-[100px]",
                }}
                className="w-[102px] lg:w-[221px] h-[30px] lg:h-[50px]"
                onClickHandler={() => {
                  setIsModalOpen("");
                  setOfferSearchInput("");
                  setSelectedOfferCode(data.code);
                }}
                // disabled={data?.toActiveDescription ? true : false}
              >
                Apply Code
              </Button>
            </div> */}
          </div>
        </div>
      </Modal>
      {/* Cancel Transaction Modal */}
      <Modal
        headerClass={"!my-0 py-6 px-9 bg-primary text-white"}
        modalClass="!p-0 rounded-xl !overflow-hidden bottom-[500px]  lg:!w-auto"
        visible={isModalOpen === "cancelReasons"}
        closeHandler={() => setIsModalOpen("")}
        closable={true}
      >
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex px-10 flex-col items-center">
          <h3 className="text-xl mt-10 font-semibold leading-6 text-gray-900" id="modal-title">
            Cancel Transaction
          </h3>
          <div className="mt-2">
            <p className="text-lg text-gray-500">
              Are you sure you want to cancel the transaction?
            </p>
          </div>
        </div>
        <div className="flex justify-around my-10">
          <Button
            className="px-4 py-[6px]"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-lg",
              fontWeight: "font-bold",
              fontColor: "text-grey-50",
            }}
            borderStyle={{
              borderRadius: "rounded-full",
            }}
            // bgColor="bg-primary"
            onClickHandler={() => navigate("/")}
          >
            Cancel
          </Button>
          <Button
            className="px-4 py-[6px]"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-lg",
              fontWeight: "font-bold",
              fontColor: "text-white",
            }}
            borderStyle={{
              borderRadius: "rounded-full",
            }}
            bgColor="bg-primary"
            onClickHandler={() => setIsModalOpen("")}
          >
            Go Back
          </Button>
        </div>
      </Modal>
      {/* Loan Sanction Letter Modal */}
      <Modal
        header="Loan Overseas"
        headerClass={"bg-white !mb-0 p-6 font-bold text-lg"}
        modalClass="!p-0 !rounded !overflow-hidden"
        visible={isModalOpen === "showLoanConfirmation"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex flex-col items-start justify-center p-6 space-y-6">
          <div>
            Have you taken a loan for overseas education and have loan disbursement letter with you?
          </div>
          <div>
            Note : You will be charged TCS at 0.5% only if you have loan disbursement letter.
          </div>
          <div className="flex justify-end w-full space-x-4">
            <Button
              id="dhjashj-oipopiknx-b"
              bgColor="bg-primary"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded",
              }}
              className="px-4 py-[6px]"
              onClickHandler={() => (
                // setConfirmedPurpose(
                //   getSelectedPurpose(
                //     selectedPurposeCode
                //       ? selectedPurposeCode
                //       : transactionData?.transaction_purpose,
                //   ),
                // ),
                setIsLoanSanctionLetter(true),
                setIsModalOpen(""),
                setSelectedPurposeCode(
                  selectedPurposeCode ? selectedPurposeCode : transactionData?.transaction_purpose,
                )
              )}
            >
              YES
            </Button>
            <Button
              id="dhjashj-F-bsaj"
              bgColor="bg-primary"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded",
              }}
              className="px-4 py-[6px]"
              onClickHandler={() => {
                // setConfirmedPurpose(
                //   getSelectedPurpose(
                //     selectedPurposeCode
                //       ? selectedPurposeCode
                //       : transactionData?.transaction_purpose,
                //   ),
                // );
                setNoLoanConfirmationLetter((prev) => !prev);
                setIsLoanSanctionLetter(false);
                setIsModalOpen(""),
                  setSelectedPurposeCode(
                    selectedPurposeCode
                      ? selectedPurposeCode
                      : transactionData?.transaction_purpose,
                  );
              }}
            >
              NO
            </Button>
          </div>
        </div>
      </Modal>

      {/* FAMILY MAINTENNACE */}
      <Modal
        header="Important Note"
        headerClass={
          "font-bold text-[18px] text-white items-center content-center text-center   bg-primary mt-0 p-5"
        }
        modalClass="!p-0 !rounded !overflow-hidden"
        visible={isModalOpen === "showFamilyMaintenance"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex flex-col items-start justify-center p-6 space-y-6 max-w-[500px] font-semibold">
          <p className="text-justify ">
            As per the RBI guidelines, we cannot process the transaction, if you are sending money
            from your INR account to your own foreign currency account. However, you can make a
            transfer to your blood relative or receive money in your foreign currency account from
            your blood relatives.
          </p>
          <p>User may receive the lesser amount due to the beneficiary bank charges.</p>
          <p>
            For more details, drop us an email at hello@moneyhop.co or call us at +91 6364001001
          </p>
          <div className="flex justify-center w-full space-x-4">
            <Button
              id="family-maintenance"
              bgColor="bg-primary"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded",
              }}
              className="px-4 py-[6px]"
              onClickHandler={() => setIsModalOpen("")}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      {/* PERSONAL GIFT OR DONATION */}

      <Modal
        header="Important Note"
        headerClass="font-bold text-[18px] text-white items-center content-center text-center bg-primary mt-0 p-5"
        modalClass="!p-0 !rounded !overflow-hidden"
        visible={isModalOpen === "PersonalGiftOrDonation"}
        closeHandler={() => setIsModalOpen("")}
        closable={false}
      >
        <div className="flex flex-col items-start justify-center p-6 space-y-6 max-w-[500px] font-semibold">
          <p className="text-justify ">
            As per the RBI guidelines, we cannot process the transaction if you are sending money
            from your INR account to your own foreign currency account for personal gifts or
            donations.
          </p>
          <p>User may receive the lesser amount due to the beneficiary bank charges.</p>
          <p>
            For more details, drop us an email at hello@moneyhop.co or call us at +91 6364001001
          </p>
          <div className="flex justify-center w-full space-x-4">
            <Button
              id="personal-gift-donation"
              bgColor="bg-primary"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded",
              }}
              className="px-4 py-[6px]"
              onClickHandler={() => setIsModalOpen("")}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      {/* INWARD KYC MODALS */}

      <Modal
        visible={
          kycStatus === "PENDING" || kycStatus === "DOCUMENTS_UPLOADED" || kycStatus === "NEW"
        }
        closeHandler={() => setKycStatus("")}
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center content-center text-center lg:flex-row lg:items-start lg:text-left ">
          <div className="">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">
              Your document verification is under review, Please try after some time.
            </h2>
            <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p>
            <Button
              bgColor="bg-primary"
              className="justify-center hidden w-40 h-10 font-bold text-white rounded-full md:flex mt-7 "
            >
              Go Home
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <KycUnsuccessful className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-3/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
          >
            Go Home
          </Button>
        </div>
      </Modal>

      <Modal
        visible={kycStatus === "REJECTED"}
        closeHandler={() => setKycStatus("")}
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left ">
          <div className="relative">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">
              Your document verification has not been approved
            </h2>
            <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              We request you to reinitiate the same.
            </p>
            <Button
              bgColor="bg-primary"
              className="hidden w-40 h-10 font-bold text-white rounded-full md:flex mt-7 "
            >
              Start
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <KycUnsuccessful className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-3/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
          >
            Start
          </Button>
        </div>
      </Modal>

      <Modal
        visible={kycStatus === "VERIFIED"}
        closeHandler={() => setKycStatus("")}
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left">
          <div className="relative">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">
              Your document verification has been approved
            </h2>
            {/* <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p> */}
            <Button
              bgColor="bg-primary"
              className="hidden w-1/2 h-10 font-bold text-white rounded-full md:flex mt-7 "
            >
              Initiate Transaction
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <Kycapproved className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-4/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
          >
            Initiate Transaction
          </Button>
        </div>
      </Modal>
      <Modal
        visible={kycStatus === "YET_TO_START"}
        closeHandler={() => setKycStatus("")}
        modalClass="h-4/5 md:h-3/5 w-full md:!w-[968px] p-4 justify-evenly "
        headerClass="mt-0"
      >
        <div className="flex flex-col items-center text-center lg:flex-row lg:items-start lg:text-left">
          <div className="relative">
            <h2 className="mt-10 mb-10 text-4xl font-bold md:mt-4">Begin your KYC!</h2>
            {/* <p className="font-normal text-xl  text-[#949494] mt-12 mb-12">
              <span className="font-bold">Note</span> - The process is completed within 24 hrs. In
              case of any help, you can reach us at care@moneyhop.co
            </p> */}
            <Button
              bgColor="bg-primary"
              className="hidden w-1/2 h-10 font-bold text-white rounded-full md:flex mt-7 "
              onClickHandler={() => navigate("/inward/user-documents")}
            >
              Begin Now
            </Button>
          </div>
          <div className="mt-16 md:mt-0">
            <Kycapproved className="" />
          </div>
          <Button
            bgColor="bg-primary"
            className="w-4/5 h-10 mt-16 font-bold text-white rounded-full md:w-40 md:hidden"
          >
            Initiate Transaction
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Modals;
