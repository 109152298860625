//#Global Imports
import { Axios } from "../axios";
import ISendMoney from "./sendMoney";
//#end Global Imports

export const getTransactionSummary = async (body: ISendMoney.IGetTransactinSummaryProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post("/remit/user/getTransactionSummary", body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const applyPromoCodeAPI = async (body: ISendMoney.IApplyPromoCodeProps) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.post(`/remit/user/${currentUser.id}/applyPromoCode`, body, {
    headers: {
      Authorization: `${currentUser.idToken}`,
    },
  });
};

export const getPurposeCode = async () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  return await Axios.get(`/remit/auth/user/purposeCode`, {
    headers: {
      Authorization: `${currentUser?.idToken}`,
    },
  });
};
