/* eslint-disable */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import Button from "../../../components/ui/Button";
import { AlertIcon, InfoIcon, LockIcon } from "../../../assets/Icons";
import Typograpy from "../../../components/ui/Typography";
import Checkbox from "../../../components/ui/Checkbox";
import Tooltip from "../../../components/ui/Tooltip";
import IUserDocuments from "../userDocuments";
import LoanSanctionLetter from "./LoanSanctionLetter";
import AdditionalDocumentsList from "./AdditionalDocumentsList";
import { transactionDetails } from "../../../store";
import { useRecoilValue } from "recoil";
import ErrorField from "../../../components/ui/ErrorField";

const AdditionalDocumentsSection: React.FC<IUserDocuments.IAdditionalDocumentsSectionProps> = ({
  selectedPurpose,
  kycDetailsData,
  setIsModalOpen,
  additionalDocData,
  kycVerificationAgreementNote,
  setSelectedPurpose,
  setDocType,
  showInfoTip,
  setShowInfoTip,
  handleUploadAdditionalDocs,
  handleAdditionalDocsSubmit,
  handleAdditionalDocRemove,
  isLoanSanctionLetter,
  loanSanctionLetterData,
  setPreViewImageSrc,
  isMobileDocumentListOpen,
  isAgree,
  numberOfVerifiedDoc,
  upload,
  uploadDocumentError,
  buttonLoader,
}) => {
  const [isProceed, setIsProceed] = useState(false);
  const purpose = selectedPurpose?.[0]?.transactionPurpose;
  const transactionData = useRecoilValue(transactionDetails);
  const loan = transactionData?.isEducation;
  const isPanVerified = kycDetailsData?.userDocs
    ?.filter((value: { docName: string | string[] }) => value.docName.includes("PAN_CARD"))
    ?.map((panCardDetails: { isVerified: boolean }) => panCardDetails.isVerified)[0];

  // const isAdditionalDocs = additionalDocData.some(
  //   (data: { name: string | string[] }) => data.name === purpose,
  // );

  // const isAdditionalDocs = additionalDocData && additionalDocData.some((data: any) => data.name === purpose);
  // console.log("isAdditionalDocs", isAdditionalDocs);
  // const tempData = additionalDocData.filter(
  //   (data: { name: string }) => data.name === selectedPurpose.value,
  // )[0];

  // const isProceed = () => {
  // switch (purposeValue) {
  //   case "Overseas Education - University Fees":
  //     return loan
  //       ? upload.studentPassport && upload.studentPassportBack && upload.offerLetter && loanSanctionLetterData?.isUploaded
  //       : upload.studentPassport && upload.studentPassportBack && upload.offerLetter;
  //   case "Overseas Education - Living Expenses":
  //     return loan
  //       ? upload.studentPassport && upload.studentPassportBack && upload.offerLetter && loanSanctionLetterData?.isUploaded
  //       : upload.studentPassport && upload.studentPassportBack && upload.offerLetter;
  //   case "Family Maintenance":
  //     return upload.relationProof && upload.beneficiaryId;
  //   case "Personal Gift or Donation":
  //     return upload.recipientPassDLssn;
  //   case "Private Visits":
  //     return upload.airTicket && upload.visa;
  //   case "Business Travel":
  //     return upload.airTicket && upload.visa;
  //   default:
  //     return false;
  // }
  // };

  useEffect(() => {
    const areMandatoryDocumentsUploaded =
      additionalDocData?.length > 0 &&
      additionalDocData?.every((item: any) => (item.isMandatory ? item.isUploaded : true));
    if (areMandatoryDocumentsUploaded) {
      setIsProceed(true);
    } else {
      setIsProceed(false);
    }
  }, [additionalDocData]);

  const selectedPurposeValue =
    (transactionData?.transaction_purpose &&
      transactionData?.transaction_purpose.replace(/\s+/g, "-")) ||
    "";
  const enabledClassname =
    "px-10 py-2 rounded-full bg-primary text-white font-bold hover:shadow-xl transition-all";
  const disabledClassname = "px-10 py-2 roudned-full bg-gray-300 text-white font-bold";

  console.log(selectedPurpose, "vvfvfv");


  return (
    <div
      className={clsx(
        isMobileDocumentListOpen ? "hidden lg:grid" : "grid",
        "grid-cols-1 px-6 lg:px-0 lg:divide-x lg:pl-10 lg:grid-cols-5 transition-all lg:divide-primary-750 lg:my-7 mt-7 pb-11",
      )}
    >
      <div className="lg:col-span-3">
        <div className="relative flex flex-col items-center lg:justify-start lg:flex-row">
          <Button
            id="akosA-=SNKak"
            bgColor="bg-white"
            fontStyle={{
              fontFamily: "",
              fontSize: "text-base",
              fontWeight: "font-normal ",
              fontColor: "text-yello-200",
            }}
            borderStyle={{
              borderRadius: "rounded",
            }}
            className="w-[378px] lg:w-[347px] h-[50px]"
          >
            <div className={clsx("flex justify-between items-center w-full px-6")}>
              {purpose}
              <LockIcon />
            </div>
          </Button>
          <Link to={"/send-money"}>
            <div
              className="text-base font-semibold text-primary-400 ml-[22px] cursor-pointer lg:mt-0 mt-7 text-center"
              onClick={() => setSelectedPurpose({ value: "Select Purpose", code: "" })}
            >
              Change purpose of payment
            </div>
          </Link>
        </div>
        {/* {isAdditionalDocs && (
          <div className="w-full lg:w-10/12 mt-9 lg:mt-8 pr-5 pl-8 py-[18px] rounded-md bg-secondary-700">
            <div className="flex items-center mb-3 lg:mb-2">
              <AlertIcon />
              <Typograpy
                text="Alert"
                className="text-lg font-bold text-yellow-100 ml-[15px]"
                tagType="div"
              />
            </div>
            {selectedPurpose.code === "S0305" && (
              <Typograpy
                text="The selected purpose of payment is only valid for students who are living abroad."
                className="mb-3 text-sm font-normal text-yellow-100 lg:text-lg lg:mb-0"
                tagType="div"
              />
            )}
            <Typograpy
              text="The documents uploaded should be according to the respective requirement."
              className="text-sm lg:text-lg font-normal text-yellow-100 mb-[18px] lg:mb-0"
              tagType="div"
            />
            <Typograpy
              text="Otherwise there is chance of a cancellation."
              className="text-sm font-bold text-yellow-100 lg:text-lg"
              tagType="div"
            />
          </div>
        )} */}
        {!kycDetailsData.isDocVerified && numberOfVerifiedDoc < 2 && (
          <div className="flex flex-col items-center justify-start mt-12 space-y-4 lg:space-y-0 lg:flex-row mb-11">
            <div className="flex items-center">
              <InfoIcon />
              <Typograpy
                tagType="div"
                text="Your document verification isn’t Completed"
                className="ml-3 mr-6 text-base font-semibold text-black-200"
              />
            </div>
            <Button
              id="akosA-=SNKak-908F"
              bgColor="bg-primary-400"
              fontStyle={{
                fontFamily: "",
                fontSize: "text-sm",
                fontWeight: "font-semibold ",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderRadius: "rounded",
              }}
              className="w-[307px] lg:w-[180px] h-[40px]"
              onClickHandler={() =>
                kycDetailsData?.userDocs?.length > 0
                  ? isPanVerified
                    ? setIsModalOpen("2")
                    : (setIsModalOpen("2"), setDocType("PAN_CARD"))
                  : isAgree
                    ? (setIsModalOpen("2"), setDocType("PAN_CARD"))
                    : setIsModalOpen("1")
              }
            >
              Complete KYC
            </Button>
            {/* <Typograpy
              tagType="div"
              text="Finish your KYC and you are good to go for all transactions."
              className="ml-6 mr-6 text-sm font-medium text-black-200"
            /> */}
          </div>
        )}
        {kycDetailsData?.isDocVerified && (
          <div className="mt-11">
            <Typograpy
              tagType="div"
              text="Please upload all the documents needed for your purpose of payment"
              className="text-base font-normal text-grey-1200 mb-[34px]"
            />
            <div className="flex flex-wrap item-center">
              {additionalDocData?.length > 0 &&
                additionalDocData?.map((data: IUserDocuments.userDocDetails, index: number) => {
                  if (transactionData?.dest_currency !== "USD" && data?.code == "i-20") {
                    return null;
                  } else {
                    return (
                      <AdditionalDocumentsList
                        data={data}
                        setIsModalOpen={setIsModalOpen}
                        handleUploadAdditionalDocs={handleUploadAdditionalDocs}
                        selectedPurposeValue={selectedPurposeValue}
                        handleAdditionalDocRemove={handleAdditionalDocRemove}
                        setPreViewImageSrc={setPreViewImageSrc}
                        index={index}
                        buttonLoader={buttonLoader}
                        key={index}
                      />
                    );
                  }
                })}
              {/* {isLoanSanctionLetter && (
                <LoanSanctionLetter
                  setIsModalOpen={setIsModalOpen}
                  loanSanctionLetterData={loanSanctionLetterData}
                  handleUploadAdditionalDocs={handleUploadAdditionalDocs}
                  selectedPurposeValue={selectedPurposeValue}
                  handleAdditionalDocRemove={handleAdditionalDocRemove}
                  setPreViewImageSrc={setPreViewImageSrc}
                />
              )} */}
            </div>
            <div className="w-2/4 flex justify-center">
              {uploadDocumentError && <ErrorField errorMessage={uploadDocumentError} />}
            </div>
          </div>
        )}
        <div>
          {(selectedPurpose?.[0]?.transactionPurpose === "Personal Gift or Donation" ||
            selectedPurpose?.[0]?.transactionPurpose === "Family Maintenance") && (
              <p className="bg-gray-100 p-2 rounded-sm text-lg">
                <span className="font-semibold ">Beneficiary ID</span> - user can upload any identity
                document of the recipient. Ex - Passport, foreign driving license, etc
              </p>
            )}
          {selectedPurpose?.[0]?.transactionPurpose === "Family Maintenance" && (
            <p className="bg-gray-100 p-2 rounded-sm  text-lg">
              <span className="font-semibold text-xl">Relationship proof</span> - user can upload any
              government issued document that shows the relationship with the beneficiary. Ex-
              Passport, Marriage certificate etc.
            </p>
          )}
        </div>
        <div className="lg:row-start-2 mt-8">
          <span className="font-bold text-base">
            To know more in detail about supporting documents
            <Link
              className="pl-2 text-primary-400"
              to="https://www.moneyhop.co/blog/commonly-asked-documents-for-international-remittance"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </Link>
          </span>
        </div>
      </div>
      {
        <div className="pt-10 lg:px-12 lg:col-span-2">
          <div className="flex flex-col justify-between h-full">
            {/* <div>
              <Typograpy
                tagType="div"
                text="All the documents mentioned for upload are mandatory for your smooth International money transfer as stated by RBI guidelines"
                className="text-base font-normal text-grey-1200 mb-[38px] hidden lg:block"
              />
              {""}
            </div> */}
            <div className="flex flex-col items-center self-end justify-center w-full mt-12 lg:mt-0">
              <div className="flex items-center mb-6 space-x-4">
                <Checkbox
                  label="Save document for the recipient"
                  value="Save document for the recipient"
                  name={"saveAdditionalDocuments"}
                  // handleChange={(e) => setIsSaveAdditionalDocuments(e.target.checked)}
                  checkboxClassname="m-[1px]"
                />
                <div className="relative">
                  <div
                    className="cursor-pointer"
                    onClick={() => setShowInfoTip((showInfoTip) => !showInfoTip)}
                  >
                    <InfoIcon fillColor="fill-primary" />
                  </div>
                  {showInfoTip && (
                    <Tooltip
                      positionOfTip="top"
                      bgColor="bg-primary"
                      tooltipStyles="cursor-default !min-h-[fit-content] absolute"
                    >
                      <Typograpy
                        tagType="div"
                        text="Your documents will be saved for the selected recipient"
                        className="bottom-1 absolute bg-primary text-sm font-medium text-center text-white"
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <button
                disabled={!isProceed}
                className={isProceed ? enabledClassname : disabledClassname}
                onClick={handleAdditionalDocsSubmit}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default AdditionalDocumentsSection;
