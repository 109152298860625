import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import clsx from "clsx";
import { Popper } from "@mui/material";

type SearchableDropdownProps = {
  options: any;
  label: any;
  mainClassname: string;
  onChange?: any;
  isCurrency?: boolean
};

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  label,
  mainClassname,
  onChange,
  isCurrency
}) => (
  <div className="flex relative w-full">
    <Autocomplete
      disablePortal={isCurrency ? false : true}
      onChange={onChange}
      options={options}
      getOptionLabel={(option: any) =>
        isCurrency ? `${option.countryName} (${option.currencyName})` : option
      }
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement={isCurrency ? "bottom" : "bottom-start"}
          className={` ${isCurrency ? "z-50 !max-w-[320px] !w-full" : "z-50 w-full"}`}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8], // Adjust as needed to control vertical spacing
              },
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
              },
            },
          ]}
        />
      )}
      renderInput={(params: any) => (
        <div className="w-full flex min-w-full">
          <TextField
            sx={{ backgroundColor: "white", marginTop: 0, minWidth: "100%", width: "100%" }}
            className={clsx("w-full min-w-full")}
            {...params}
            label={label}
          />
        </div>
      )}
      className={clsx(mainClassname)}
    />
  </div>
);
