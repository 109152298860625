/* eslint-disable */
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { CopyIcon, MoneyHopLogoWhite } from "../../assets/Icons";
import { Modal as MuiModal } from "@mui/material";
import { transactionDetails } from "../../store";
import PaymentProps from "./payment";
import ErrorField from "../../components/ui/ErrorField";
import { handleTransactionComplete } from "../../services/transaction";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import { neftBankDetails } from "../../services/payments";
import { formatNumber } from "../../utils";

let upiDownError: any;
let netBankDownError: any;

export const Modals: FunctionComponent<PaymentProps.Modals> = ({
  setIsModalOpen,
  isModalOpen,
  acNumber,
  setAcNumber,
  ifsc,
  setIfsc,
  handleNetBanking,
  upiId,
  setUpiId,
  handleUpi,
  debitCardDetails,
  setDebitCardDetails,
  handleDebitCard,
  bankName,
  setBankName,
  upiError,
  pennyError,
  bankList,
  netBankError,
  downNetBanks,
  downVpas,
  totalPay,
  disablePay,
  setAccountNumber,
  accountNumber,
  setIfscNumber,
  ifscNumber,
  setDisablePay,
  onlyNeft,
  isLoading,
  debitCardError,
  setDebitCardError,
  pennyLoading,
  setNetBankError,
  setPennyError,
  upiLoading,
  selectedPurposeCode
}) => {
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [copied, setCopied] = useState(false);
  const [copiedItem, setCopiedItem] = useState("");
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const transactionData = useRecoilValue(transactionDetails);
  const resetTransactionDetails = useResetRecoilState(transactionDetails);
  const [bankDetails, setBankDetails] = useState<any>([]);

  useEffect(() => {
    if (isModalOpen === "netBanking") {
      setNetBankError("");
      setPennyError("");
      setSelectedOption("");
    }
  }, [isModalOpen, setNetBankError, setPennyError, setSelectedOption]);

  const handleChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);
    setBankName(selectedValue);
    if (isModalOpen === "netBanking" && downNetBanks && downNetBanks.includes(selectedValue)) {
      netBankDownError = `We are currently facing low success rate for ${selectedValue}, please try again later or try with different bank account.`;
    } else {
      netBankDownError = null;
    }
  };

  const handleCopied = (name: string) => {
    setCopiedItem(name);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  //   const bankDetails = `
  // For NEFT/RTGS transfers:
  // A/C Name: UNOBANC PVT LTD AD REFERRAL POOL AC
  // Bank Name: YES BANK
  // Account Number: YES9996364001001
  // IFSC: YESB0CMSNOC
  // BRANCH: IFC, Mumbai

  // Transfers Via App like Gpay, Phonepe, Paytm etc.:
  // A/C Name: UNOBANC PVT LTD AD REFERRAL POOL AC
  // Bank Name: YES BANK
  // Account Number: 041971400000082
  // IFSC: YESB0000419
  // BRANCH: IFC, Mumbai
  // `;

  const handleCopyBankDetails = () => {
    const bankDetailsToCopy = `For NEFT/RTGS transfers:
    A/C Name: ${bankDetails?.accountHolderName}
    Bank Name: ${bankDetails?.bankName}
    Account Number: ${bankDetails?.accountNumber}
    IFSC: ${bankDetails?.ifscCode}`;

    navigator.clipboard
      .writeText(bankDetailsToCopy)

      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
        toast.success("Copied");
      })
      .catch((err) => {
        toast.error("Failed to copy");
      });
  };

  const CopiedText = () => {
    return (
      <div className={`absolute top-5 flex justify-center text-[10px] -left-4 px-1 rounded-full `}>
        Copied
      </div>
    );
  };

  useEffect(() => {
    neftBankDetails(onlyNeft)
      .then((response) => {
        setBankDetails(response?.data);
      })
      .catch((error) => console.error("ERROR", error));
  }, [isModalOpen]);

  useEffect(() => {
    netBankDownError = null;
    upiDownError = null;
    let downListedVpas = downVpas && downVpas.join(", ");
    if (downVpas?.length != 0) {
      upiDownError = `We are currently facing low success rate for the followoing vpa's : ${downListedVpas}`;
    }
  }, [isModalOpen]);

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    width: "100%",
    // bgcolor: "background.paper",
    // boxShadow: 24,
  };

  const handleCopy = (text: any) => {
    // window.console.log(text);
    navigator.clipboard.writeText(text);
  };

  const handleClose = () => {
    setIsModalOpen("");
    setAcNumber("");
    setIfsc("");
    setSelectedOption("");
    setBankName("");
  };

  const handleCloseUpiModal = () => {
    setIsModalOpen("");
    setUpiId("");
  };

  return (
    <div>
      <ToastContainer position="top-center" />
      <div>
        <MuiModal
          open={isModalOpen === "netBanking"}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="rounded"
        >
          <Box sx={style}>
            <div className="flex justify-center items-center w-full">
              <div className="flex flex-col lg:w-[600px] h-[500px] bg-white">
                <div
                  id="header"
                  className="flex items-center w-full justify-evenly bg-primary-200 lg:space-x-20"
                >
                  <span className="text-white lg:font-[22px] flex items-center ">Net Banking</span>
                  <MoneyHopLogoWhite />
                </div>
                <div className="flex flex-col items-center w-full h-full justify-evenly">
                  <div id="text/body" className="flex flex-col w-full px-10 space-y-5">
                    <div className="w-full !p-0 flex items-center justify-center flex-col space-y-5">
                      <TextField
                        variant="outlined"
                        required
                        label="Account Number"
                        value={acNumber}
                        onChange={(e: any) => setAcNumber(e.target.value)}
                        className="lg:w-4/5 w-full px-5 py-5 bg-white border border-black rounded-lg"
                      />
                      <TextField
                        variant="outlined"
                        required
                        label="IFSC Code"
                        value={ifsc}
                        onChange={(e: any) => setIfsc(e.target.value)}
                        className="lg:w-4/5 w-full px-5 py-5 bg-white border border-black rounded-lg"
                      />
                      <FormControl className="lg:w-4/5 w-full">
                        <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedOption}
                          label="Select Bank"
                          onChange={handleChange}
                        >
                          {bankList &&
                            bankList?.map((option: any) => (
                              <MenuItem value={option?.bankCode}>{option?.bankName}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex items-center w-full py-3 bg-blue-100 justify-evenly">
                    <div className="flex flex-col items-center">
                      <span>You Pay</span>
                      <span className="font-sans font-bold text-black">
                        {transactionData.isAdvancePay
                          ? formatNumber(transactionData.advance_pay)
                          : formatNumber(transactionData.total_pay)}
                      </span>
                    </div>
                    <button
                      onClick={handleNetBanking}
                      className={clsx(
                        disablePay
                          ? " bg-grey-200 hover:border-grey-200"
                          : "bg-primary-200 hover:border-primary-200",
                        "px-8 w-40 py-3 text-white rounded-full bg-primary-200 hover:border-2 hover:border-primary-200",
                      )}
                    >
                      {pennyLoading ? (
                        <div className="mx-10 loader ease-linear rounded-full border-2 border-t-2 border-t-gray-300 border-white h-5 w-5"></div>
                      ) : (
                        "Pay Now"
                      )}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setIsModalOpen("");
                      setNetBankError("");
                      setPennyError("");
                    }}
                    className="font-bold text-blue-500 cursor-pointer flex flex-col items-center"
                  >
                    Go Back
                    {pennyError && (
                      <ErrorField
                        errorMessage={pennyError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                    {netBankError && (
                      <ErrorField
                        errorMessage={netBankError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                    {netBankDownError && !netBankError && !pennyError && (
                      <ErrorField
                        errorMessage={netBankDownError}
                        errorClassname="self-center font-normal px-20 text-center"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </MuiModal>
      </div>
      <div>
        <MuiModal
          open={isModalOpen === "neft"}
          onClose={() => setIsModalOpen("")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="rounded"
        >
          <Box sx={style}>
            <div className="flex flex-col justify-center w-full h-auto items-center p-4">
              <div
                className={`flex flex-col bg-white ${onlyNeft ? "lg:w-[900px]" : "lg:w-[600px]"}`}
              >
                <div
                  id="header"
                  className="flex items-center w-full justify-evenly bg-primary-200 lg:space-x-20"
                >
                  <span className="text-white lg:font-[22px] flex items-center ">NEFT/RTGS</span>
                  <MoneyHopLogoWhite />
                </div>
                <div className="flex flex-col items-center w-full h-full mt-7 mb-5 justify-evenly">
                  <div id="text/body" className="flex flex-col w-full px-10 space-y-3">
                    <span className="font-sans text-lg font-bold">For NEFT/RTGS transfers :</span>
                    <span className="flex items-center space-x-3">
                      A/C Name : {bankDetails?.accountHolderName}
                      <div
                        onClick={() => {
                          handleCopy(bankDetails?.accountHolderName);
                          handleCopied("ac_name");
                        }}
                        className="ml-5 relative transition-all cursor-pointer"
                      >
                        {copied && copiedItem === "ac_name" && <CopiedText />}
                        <CopyIcon />
                      </div>
                    </span>
                    <span className="flex items-center space-x-3">
                      Bank Name : {bankDetails?.bankName}{" "}
                      <div
                        onClick={() => {
                          handleCopy(bankDetails?.bankName);
                          handleCopied("bank_name");
                        }}
                        className="ml-5 relative transition-all cursor-pointer"
                      >
                        {copied && copiedItem === "bank_name" && <CopiedText />}
                        <CopyIcon />
                      </div>
                    </span>
                    <span className="flex items-center space-x-3">
                      Account Number : {bankDetails?.accountNumber}{" "}
                      <div
                        onClick={() => {
                          handleCopy(bankDetails?.accountNumber);
                          handleCopied("acc_number");
                        }}
                        className="ml-5 relative transition-all cursor-pointer"
                      >
                        {copied && copiedItem === "acc_number" && <CopiedText />}
                        <CopyIcon />
                      </div>
                    </span>
                    <span className="flex items-center space-x-3 relative">
                      IFSC : {bankDetails?.ifscCode}{" "}
                      <div
                        onClick={() => {
                          handleCopy(bankDetails?.ifscCode);
                          handleCopied("ifsc_copy");
                        }}
                        className="relative ml-5 transition-all cursor-pointer"
                      >
                        {copied && copiedItem === "ifsc_copy" && <CopiedText />}
                        <CopyIcon />
                      </div>
                    </span>
                    {onlyNeft ? (
                      <span className="flex items-center space-x-3 ">
                        BRANCH : GROUND FLOOR NAMAN CHAMBERS, C-32, G-BLOCK, BANDRA-KURLA COMPLEX,
                        <br />
                        BANDRA EAST, MUMBAI 400051{" "}
                        <div
                          onClick={() => {
                            handleCopy(`GROUND FLOOR NAMAN CHAMBERS, C-32, G-BLOCK, BANDRA-KURLA  COMPLEX, BANDRA EAST,MUMBAI 400051
                            `);
                            handleCopied("branch_copy");
                          }}
                          className="ml-5 transition-all cursor-pointer relative "
                        >
                          {copied && copiedItem === "branch_copy" && <CopiedText />}
                          <CopyIcon />
                        </div>
                      </span>
                    ) : null}
                  </div>
                  {/* { onlyNeft && (
                    <div id="text/body" className="flex flex-col w-full px-10 space-y-3 mt-5">
                      <span className="font-sans font-bold text-lg">
                        Transfers Via App like Gpay, Phonepe, Paytm etc. :
                      </span>
                      <span className="flex items-center space-x-3">
                        A/C Name :{" "}
                        {onlyNeft
                          ? "UNOBANC PVT LTD AD REFERRAL POOL AC"
                          : "Intermediary Outward Remittance Referral A/C"}
                        <div
                          onClick={() => {
                            handleCopy(
                              onlyNeft
                                ? "UNOBANC PVT LTD AD REFERRAL POOL AC"
                                : "Intermediary Outward Remittance Referral A/C",
                            );
                            handleCopied("upi_ac_name");
                          }}
                          className="ml-5 relative transition-all cursor-pointer"
                        >
                          {copied && copiedItem === "upi_ac_name" && <CopiedText />}
                          <CopyIcon />
                        </div>
                      </span>
                    ) : null}
                  </div>
                  )} */}
                  <div className="mt-5 flex flex-col self-start ml-10">
                    <div className="font-bold">Note:</div>
                    <div> - The Payment should be made within the next 30 hours.</div>
                    <div>
                      - Please make sure that the payment is being made only from your account.
                    </div>
                    {!selectedPurposeCode?.includes("Overseas") ?
                      <div>
                        - Your payment status will get updated during banking hours, For any queries,  <br />
                        <span className="px-2">
                          do reach us at hello@moneyhop.co
                        </span>
                      </div>
                      :
                      <div>
                        {" "}
                        - After making the bank transfer, it may take up to 1 hour to <br />
                        <span className="px-2">get the payment status updated.</span>{" "}
                      </div>
                    }
                    {/* <div className="font-bold">
                        {" "}
                        - We have updated our NEFT account details to YES Bank for Family
                        maintenance and Gift purpose code.
                      </div> */}
                    <span className="text-[14px] text-blue-600 ">
                      - Optional- You can upload the payment proof after the fund transfer in the History tab
                    </span>
                  </div>
                  <div className="flex items-center w-full py-3 mt-5 bg-blue-100 justify-evenly">
                    <div className="flex flex-col items-center ml-5 ">
                      <span>You Pay</span>
                      <span className="font-sans font-bold text-black">
                        {transactionData.isAdvancePay
                          ? formatNumber(transactionData.advance_pay)
                          : formatNumber(transactionData.total_pay)}
                      </span>
                    </div>

                    <div className="flex items-center gap-x-4 ml-10">
                      <div className="relative">
                        <button
                          onClick={handleCopyBankDetails}
                          className="px-4 py-2 text-primary-200 transition-all rounded-full bg-white flex items-center justify-center hover:text-primary-200 hover:border-2 hover:border-primary-200"
                          style={{ minWidth: "150px" }}
                        >
                          <CopyIcon />
                          <span className="px-2">Copy Bank Details</span>
                        </button>
                      </div>
                      <button
                        onClick={() => {
                          navigate("/confirmation");
                          handleTransactionComplete();
                          resetTransactionDetails();
                        }}
                        className="px-4 py-2 text-white transition-all rounded-full bg-primary-200 hover:bg-white hover:text-primary-200 hover:border-2 hover:border-primary-200"
                        style={{ minWidth: "150px" }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>

                  <span
                    onClick={() => setIsModalOpen("")}
                    className="mt-5 font-bold text-blue-500 cursor-pointer"
                  >
                    Go Back
                  </span>
                </div>
              </div>
            </div>
          </Box>
        </MuiModal>
      </div>
      <div>
        <MuiModal
          open={isModalOpen === "debitCard"}
          onClose={() => setIsModalOpen("")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="rounded"
        >
          <Box sx={style}>
            <div className="flex  w-full p-2 justify-center items-center">
              <div className="flex flex-col lg:w-[600px] h-[600px] bg-white">
                <div
                  id="header"
                  className="flex items-center w-full justify-evenly bg-primary-200 lg:space-x-20"
                >
                  <span className="text-white lg:font-[22px] flex items-center ">
                    <img src="/images/png/debitcard.png" alt="" className="scale-75" />
                    Debit Card
                  </span>
                  <MoneyHopLogoWhite />
                </div>
                <div className="flex flex-col items-center w-full h-full justify-evenly">
                  <div id="text/body" className="flex flex-col px-2 lg:px-10 space-y-5">
                    <div id="bodo-top" className="flex flex-col">
                      <span>Transfer money from your bank account using your debit card.</span>
                    </div>
                    <img src="/images/png/weAcceptDebitCard.png" className="scale-50" />
                    <div className="w-full !p-0 flex items-center justify-center flex-col space-y-5">
                      <TextField
                        variant="outlined"
                        label="Card Number"
                        type="number"
                        value={debitCardDetails?.number}
                        onChange={(e: any) =>
                          setDebitCardDetails({ ...debitCardDetails, number: e.target.value })
                        }
                        className="w-4/5 px-5 py-5 bg-white border border-black rounded-lg"
                      />
                      <TextField
                        variant="outlined"
                        label="Card Holder Name"
                        type="text"
                        value={debitCardDetails?.name}
                        onChange={(e: any) =>
                          setDebitCardDetails({ ...debitCardDetails, name: e.target.value })
                        }
                        className="w-4/5 px-5 py-5 bg-white border border-black rounded-lg"
                      />
                      <div className="flex items-center justify-between w-4/5 space-x-5">
                        <TextField
                          variant="outlined"
                          label="Expiry Month (MM)"
                          type="number"
                          value={debitCardDetails?.expiry_month}
                          onChange={(e: any) =>
                            setDebitCardDetails({
                              ...debitCardDetails,
                              expiry_month: e.target.value,
                            })
                          }
                          className="w-3/5 px-5 py-5 bg-white border border-black rounded-lg"
                        />
                        <TextField
                          variant="outlined"
                          label="Expiry Year (YY)"
                          type="number"
                          value={debitCardDetails?.expiry_year}
                          onChange={(e: any) =>
                            setDebitCardDetails({
                              ...debitCardDetails,
                              expiry_year: e.target.value,
                            })
                          }
                          className="w-3/5 px-5 py-5 bg-white border border-black rounded-lg"
                        />
                        <TextField
                          variant="outlined"
                          label="CVV"
                          type="number"
                          value={debitCardDetails?.cvv}
                          onChange={(e: any) =>
                            setDebitCardDetails({ ...debitCardDetails, cvv: e.target.value })
                          }
                          className="w-3/5 px-5 py-5 bg-white border border-black rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center w-full py-3 bg-blue-100 justify-evenly">
                    <div className="flex flex-col items-center">
                      <span>You Pay</span>
                      <span className="font-sans font-bold text-black">
                        {transactionData.isAdvancePay
                          ? formatNumber(transactionData.advance_pay)
                          : formatNumber(transactionData.total_pay)}
                      </span>
                    </div>
                    <button
                      onClick={handleDebitCard}
                      className={clsx(
                        disablePay
                          ? " bg-grey-200 hover:border-grey-200"
                          : "bg-primary-200 hover:border-primary-200",
                        "px-8 w-40 py-3 text-white transition-all rounded-full bg-primary-200 hover:bg-white hover:text-primary-200 hover:border-2 hover:border-primary-200",
                      )}
                    >
                      {isLoading ? (
                        <div className="mx-10 loader ease-linear rounded-full border-2 border-t-2 border-t-primary-300 border-primary h-5 w-5"></div>
                      ) : (
                        "Pay Now"
                      )}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setIsModalOpen("");
                      setDebitCardError("");
                    }}
                    className="font-bold text-blue-500 flex flex-col items-center cursor-pointer"
                  >
                    Go Back
                    {debitCardError && (
                      <ErrorField
                        errorMessage={debitCardError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </MuiModal>
      </div>
      <div>
        <MuiModal
          open={isModalOpen === "upi"}
          onClose={() => handleCloseUpiModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="rounded"
        >
          <Box sx={style}>
            <div className="flex justify-center items-center w-full">
              <div className="flex flex-col lg:w-[600px] h-[650px] bg-white">
                <div
                  id="header"
                  className="flex items-center w-full justify-evenly bg-primary-200 lg:space-x-20"
                >
                  <span className="text-white lg:font-[22px] flex items-center">UPI</span>
                  <MoneyHopLogoWhite />
                </div>
                <div className="flex flex-col items-center w-full h-full justify-evenly">
                  <div id="text/body" className="flex flex-col w-full px-10 space-y-5">
                    <div id="bodo-top" className="flex flex-col mt-5">
                      {(totalPay && totalPay <= 100000) ||
                        totalPay === undefined ||
                        Number.isNaN(totalPay) ? (
                        <span>
                          Transfer money from your bank account using UPI with your registered VPA.
                          We accept the following:
                        </span>
                      ) : (
                        <span className="text-center text-[12px] text-red-100">
                          An amount of more than Rs 1,00,000 cannot be processed through UPI; Please
                          choose another payment mode.
                        </span>
                      )}
                    </div>
                    <img src="/images/png/weAcceptUpi.png" className="scale-50" />
                    <div className="w-full !p-0 flex items-center justify-center flex-col space-y-5">
                      <TextField
                        variant="outlined"
                        label="Enter UPI Id/VPA"
                        type="text"
                        required
                        value={upiId}
                        onChange={(e: any) => setUpiId(e.target.value)}
                        className="lg:w-4/5 w-full px-5 py-5 bg-white border border-black rounded-lg"
                        inputProps={{ pattern: "^.+@.+$" }}
                      />
                      <TextField
                        variant="outlined"
                        label="Enter Account No."
                        type="text"
                        required
                        value={accountNumber}
                        onChange={(e: any) => setAccountNumber(e.target.value)}
                        className="lg:w-4/5 w-full px-5 py-5 bg-white border border-black rounded-lg"
                        inputProps={{ pattern: "^.+@.+$" }}
                      />
                      <TextField
                        variant="outlined"
                        label="Enter IFSC Code."
                        type="text"
                        required
                        value={ifscNumber}
                        onChange={(e: any) => setIfscNumber(e.target.value)}
                        className="lg:w-4/5 w-full px-5 py-5 bg-white border border-black rounded-lg"
                        inputProps={{ pattern: "^.+@.+$" }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center w-full py-3 bg-blue-100 justify-evenly">
                    <div className="flex flex-col items-center">
                      <span>You Pay</span>
                      <span className="font-sans font-bold text-black">
                        {transactionData.isAdvancePay
                          ? formatNumber(transactionData.advance_pay)
                          : formatNumber(transactionData.total_pay)}
                      </span>
                    </div>
                    <button
                      onClick={handleUpi}
                      className={clsx(
                        disablePay
                          ? " bg-grey-200 hover:border-grey-200"
                          : "bg-primary-200 hover:border-primary-200 cursor-pointer",
                        "px-8 py-3 text-white transition-all rounded-full",
                      )}
                      disabled={disablePay || upiLoading}
                    >
                      {upiLoading ? (
                        <div className="mx-10 loader ease-linear rounded-full border-2 border-t-2 border-t-gray-300 border-white h-5 w-5"></div>
                      ) : (
                        "Pay Now"
                      )}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setIsModalOpen("");
                      setPennyError("");
                      setNetBankError("");
                      setDisablePay(false);
                    }}
                    className="font-bold text-blue-500 cursor-pointer flex flex-col items-center"
                  >
                    Go Back
                    {pennyError && (
                      <ErrorField
                        errorMessage={pennyError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                    {upiError && (
                      <ErrorField
                        errorMessage={upiError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                    {netBankError && (
                      <ErrorField
                        errorMessage={netBankError}
                        errorClassname="self-center font-normal"
                      />
                    )}
                    {netBankDownError && !netBankError && !pennyError && (
                      <ErrorField
                        errorMessage={netBankDownError}
                        errorClassname="self-center font-normal px-20 text-center"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </MuiModal>
      </div>
    </div>
  );
};
