/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLottie } from "lottie-react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { AxiosResponse } from "axios";
import { motion } from "framer-motion";

import Button from "../../components/ui/Button";
import {
  additionalDocumentsList,
  additionalIsTcsDocumentsList,
  kycDocsId,
  kycStepperSteps,
  additionalDocumentsListWithFlywire,
  additionalIsTcsDocumentsListWithFlywire,
  // kycVerificationAgreementNote,
  loanSanctionLetterDocData,
  stepperSteps,
  verifiedDocumentsData,
  additionalDocumentListWithGic,
  additionalIsTcsDocumentsListWithGic,
} from "../../utils";
import {
  confirmedPurpose,
  kycDetails,
  transactionDetails,
  transactionLeadIDS,
  userAdditionalDocs,
  userDetails,
  additionalData,
  additionalDocs,
} from "../../store";
import { LeftArrowIcon, ReloadIcon } from "../../assets/Icons";
import Typograpy from "../../components/ui/Typography";
import { getUserDocs } from "../../services/userDetails";
import * as animationData from "../../assets/lottieAnimationsData/scanDocuments.json";
import {
  eVerifyDL,
  eVerifyPan,
  eVerifyVoterIdCard,
  sendAdhatOTP,
  uploadAdditionalDocs,
  uploadDocuments,
  validateAdharOTP,
  verifyAadharCard,
  verifyDrivingLicenseDocument,
  verifyPassportBack,
  verifyPassportFront,
  verifyVoterIdCardDocument,
  downloadCKYC,
  getCkycStatus,
  sendCkycOtp,
  validateCkycOtp,
  searchCkyc,
  uploadAadharBack,
  noAadharValidation,
  getAdditionalDox,
} from "../../services/kyc";
import KycModals from "./KycModals";
import NavbarAndStepperSection from "./NavbarAndStepperSection";
import AdditionalDocumentsSection from "./AdditionalDocumentsSection";
import DocumentsListSection from "./KycModals/UploadDocumentsModal/DocumentsListSection";
import mixpanel from "mixpanel-browser";
import IUserDocuments from "./userDocuments";
import { getRecipientType } from "../../services/recipientDetailsPage";
import { UploadDocument } from "../../store/store";

const style = {
  height: 400,
  width: 400,
};

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const UserDocuments = () => {
  const [isModalOpen, setIsModalOpen] = React.useState<string>("");
  const [isAgree, setIsAgree] = React.useState<boolean>(false);
  const [docType, setDocType] = React.useState<string>("");
  const [adharOTPValue, saveAdharOTPValue] = React.useState<string>("");
  const [adharOTPError, setAdharOTPError] = React.useState<string>("");
  const [date, saveDate] = React.useState(Date.now());
  const [dateKey, saveDateKey] = React.useState(0);
  const [isDocScan, setIsDocScan] = React.useState<boolean>(false);
  const [docDob, setDocDob] = React.useState<string>("");
  const [entityId, setEntityId] = React.useState<string>("");
  const [otpReferenceNo, setOtpReferenceNo] = React.useState<string>("");
  const [isSaveAdditionalDocuments, setIsSaveAdditionalDocuments] = React.useState<boolean>(false);
  const [showInfoTip, setShowInfoTip] = React.useState<boolean>(false);
  const [transactionLeadIds, setTransactionLeadIds] = React.useState<Array<any>>([]);
  const [leadIds, setLeadIds] = React.useState({});
  const [uploaded, setAdditionalDocUploaded] = useRecoilState(userAdditionalDocs);
  const [uploadDocumentError, setUploadDocumentError] = React.useState<string>("");
  const [aadhaarLinked, setAadhaarLinked] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = React.useState({
    aadhaarOtpLoader: false,
    panLoader: false,
    aadhaarBackLoader: false,
    voterCardLoader: false,
    drivingLoader: false,
    passportBackLoader: false,
    passportFrontLoader: false,
    "Student-Passport": false,
    "Student-Passport Back": false,
    "University-Offer-Letter": false,
    "Rent-Agreement-Invoice": false,
    "Relationship-Proof": false,
    "Beneficiary-ID": false,
    "Recipient-passport-Driving-license-SSN-number": false,
    "Gift-Declaration": false,
    "Air-Ticket-Copy": false,
    "Visa": false,
    "Loan-Sanction-letter": false,
  });

  const resetUserAdditionalDocs = useResetRecoilState(userAdditionalDocs);
  const categoryType = sessionStorage.getItem("categoryType");

  const { state } = useLocation();
  useEffect(() => {
    if (state?.from === "profile") {
      setIsAgree(true);
      setIsModalOpen("");
    }
    if (state?.from === "send-money") {
      setIsAgree(true);
      setIsModalOpen("");
    }
  }, [state]);

  useEffect(() => {
    resetUserAdditionalDocs();
  }, []);

  const setTransactionLeadID = useSetRecoilState(transactionLeadIDS);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const [uploadedFileData, setUploadedFileData] = React.useState({
    pdfFile: false,
    fileName: "",
  });
  const [panCardDetails, setPanCardDetails] = React.useState({
    panNumber: "",
    panNameOnCard: "",
    panRequestId: "",
    isPanVerified: false,
    isPanUploaded: false,
    uploadedPanFile: "",
    messagePan: "",
    uploadedDocError: "",
  });
  const [adharCardDetails, setAdharCardDetails] = React.useState({
    aadharNumber: "",
    aadharNameOnCard: "",
    isAadharDocVerified: false,
    isAadharDocUploaded: false,
    isAadharBackUploaded: false,
    uploadedAdharFrontFile: "",
    uploadedAdharBackFile: "",
    messageAadhar: "",
    uploadedDocError: "",
    uploadedBackError: "",
  });
  const [drivingLicenseDetails, setDrivingLicenseDetails] = React.useState({
    dlNumber: "",
    dlNameOnCard: "",
    dlRequestId: "",
    isDlDocVerified: false,
    isDLFrontUploaded: false,
    uploadedDlFrontFile: "",
    dlBackFile: "",
    isDLBackUploaded: false,
    dlBackImageFileName: "",
    messageDLVerified: "",
    errorMessageDLVerified: "",
    uploadedDocError: "",
    dlDob: "",
  });
  const [passportDetails, setPassportDetails] = React.useState({
    passportIdNumber: "",
    passportBackIdNumber: "",
    passportNameOnCard: "",
    uploadedPassportFrontFile: "",
    isPassportDocVerified: false,
    isPassportDocUploaded: false,
    isPassportBackDocVerified: false,
    isPassportBackUploaded: false,
    uploadedPassportBackFile: "",
    messagePassport: "",
    messageBackPassport: "",
    passportAddress: "",
    passportCity: "",
    passportState: "",
    passportDob: "",
    passportPincode: "",
    uploadedFrontDocError: "",
    uploadedBackDocError: "",
  });
  const [voterIDCardDetails, setVoterIDCardDetails] = React.useState({
    voterNumber: "",
    voterNameOnCard: "",
    voterRequestId: "",
    isVIDocVerified: false,
    isVoterFrontUploaded: false,
    uploadedVoterFrontCardFile: "",
    voterBackCardFile: "",
    isVoterBackUploaded: false,
    VoterIdBackImageFileName: "",
    messageVIVerify: "",
    uploadedDocError: "",
  });
  const transactionDetailsData = useRecoilValue(transactionDetails);
  // const [additionalDocData, setAdditionalDocData] = React.useState(
  //   transactionDetailsData?.tcs === 0 ? additionalDocumentsList : additionalIsTcsDocumentsList,
  // );
  const [preViewImageSrc, setPreViewImageSrc] = React.useState("");
  const [loanSanctionLetterData, setLoanSanctionLetterData] =
    React.useState(loanSanctionLetterDocData);
  const [numberOfVerifiedDoc, setNumberOfVerifiedDoc] = React.useState<number>(0);
  const [isMobileDocumentListOpen, setIsMobileDocumentListOpen] = React.useState<boolean>(false);
  const [ckycPhase, setCKYCPhase] = React.useState<number>(1);
  const [ckycData, setCkycData] = React.useState({} as IUserDocuments.ckycData);
  const [ckycAdharOTPValue, saveCkycAdharOTPValue] = React.useState<string>("");
  const [ckycMessage, setCkycMessage] = React.useState({ successMsg: "", errorMsg: "" });
  const [ckycStatus, setCkycStatus] = React.useState<string>("");
  const userData = useRecoilValue(userDetails);
  const isUseEffectFired = React.useRef(false);
  const [kycDetailsData, setKycDetails] = useRecoilState(kycDetails);
  const [additionalDocData, setAdditionalDocData] = useRecoilState(additionalData);
  const [listOfDocuments, setListOfDocuments] = React.useState<any>();
  const [additionalDocsUpload, setAdditionalDocsUpload] = useRecoilState(additionalDocs);
  const [selectedPurpose, setSelectedPurpose] = useRecoilState(confirmedPurpose);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const navigate = useNavigate();
  const recipientID = JSON.parse(sessionStorage.getItem("recipient_id") || "null");
  const { View } = useLottie(defaultAnimationOptions, style);
  const [initialDocumentsLoaded, setInitialDocumentsLoaded] = useState(false);

  console.log(selectedPurpose, "sell");

  const purposeCode = Array.isArray(selectedPurpose)
    ? selectedPurpose[0]?.purposeCode
    : "";

  const purposeValue = Array.isArray(selectedPurpose)
    ? selectedPurpose[0]?.transactionPurpose
    : "";


  useEffect(() => {
    setTransactionLeadID(transactionLeadIds);
  }, [transactionLeadIds]);

  useEffect(() => {
    setTransactionLeadIds(Object.values(leadIds));
  }, [leadIds]);

  useEffect(() => {
    if (initialDocumentsLoaded) {
      getAdditionalDocs();
    }
  }, [initialDocumentsLoaded]);

  const listOfPurposeforAdditionalDocuments =
    (additionalDocData?.length > 0 && additionalDocData?.map((data) => data?.name)) || [];

  const handlerCkycStatus = () => {
    return getCkycStatus(userData?.id)
      .then((response) => {
        setCkycStatus(response?.data);
        return response?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getRecipientType(
      transactionDetailsData?.transaction_purpose,
      transactionDetailsData?.dest_currency,
      categoryType,
    )
      .then((data) => {
        let count = -1;
        const neededDocs = data.map((item: any) => {
          count++;
          return {
            id: count,
            name: item.document,
            code: item.document.replace(/\s+/g, "_"),
            isUploaded: false,
            uploadedId: 0,
            fileName: "",
            isMandatory:
              item?.document === "Loan Disbursement Letter"
                ? transactionDetailsData?.isEducation
                  ? true
                  : item.isRequired
                : item.isRequired,
            uploadedFile: "",
            errorMessage: "",
          };
        });
        setInitialDocumentsLoaded(true);
        setListOfDocuments(neededDocs);
        setAdditionalDocData(neededDocs);
      })
      .catch((error) => {
        window.console.log("ERROR", error);
      });
    window.console.log("transactionDetailsData", transactionDetailsData);
  }, [transactionDetailsData]);

  const getAdditionalDocs = () => {
    if (listOfDocuments?.length > 0) {
      getAdditionalDox(purposeCode)
        .then((res) => {
          const updatedList = [...listOfDocuments];
          res?.data?.leadDocList?.forEach((item: any) => {
            let newName = item?.docName?.replace(/[\s\-_]+/g, "_");
            if (newName === "Student_Passport") {
              newName += "_Front";
            } else if (newName === "Student_Passport_Back") {
              newName = "_Student_Passport_Back";
            }
            const existingDocIndex = updatedList.findIndex((doc: any) => doc.code === newName);
            setLeadIds(prevLeadIds => ({ ...prevLeadIds, [newName]: item?.id }));

            if (existingDocIndex !== -1) {
              const areMandatory =
                additionalDocData &&
                additionalDocData?.every(
                  (docData: any) => docData.code === newName && docData.isMandatory,
                );

              updatedList[existingDocIndex] = {
                ...updatedList[existingDocIndex],
                isUploaded: true,
                uploadedId: item?.id,
                fileName: item?.docName,
                uploadedFile: item?.docUrl,
                // uploadedFile:  URL.createObjectURL(item?.docUrl),
                isMandatory: areMandatory,
              };
            }
          });

          setListOfDocuments(updatedList);
          setAdditionalDocData(updatedList);
        })
        .catch((err) => console.error("Error:", err))
        .finally();
    }
  };

  const commonHandler = (response: AxiosResponse<any, any>, ckycStatusValue: string) => {
    const isPanUploaded = response?.data?.userDocs?.filter(
      (value: { docName: string | string[] }) => value.docName.includes("PAN_CARD"),
    )?.length;
    setNumberOfVerifiedDoc(response?.data?.isDocVerified ? 2 : isPanUploaded === 1 ? 1 : 0);

    if ("doc_dob" in response.data) {
      setDocDob(response.data.doc_dob);
    }

    response?.data?.userDocs.length > 0 &&
      !response?.data?.isDocVerified &&
      setIsMobileDocumentListOpen(true);

    listOfPurposeforAdditionalDocuments.includes(purposeValue)
      ? setIsModalOpen("")
      : response?.data?.isDocVerified
        ? ""
        : response?.data["userDocs"].length > 0
          ? response?.data["userDocs"]
            .filter((value: { docName: string | string[] }) => value.docName.includes("PAN_CARD"))
            .map((panCardDetails: { isVerified: boolean }) => panCardDetails.isVerified)[0]
            ? ckycStatusValue === "FAILED"
              ? (setIsModalOpen("2"), setDocType("AADHAR_CARD"))
              : (setIsModalOpen("2"), setDocType("CKYC"), setCKYCPhase(1))
            : (setIsModalOpen("2"), setDocType("PAN_CARD"))
          : (setIsModalOpen(state?.showKycSidePopUp === false ? "2" : "UnverifiedKYC"),
            setDocType("PAN_CARD"));

    response?.data["userDocs"].length > 0 &&
      response?.data["userDocs"].map(
        (values: {
          idDoc: number;
          idNumber: string;
          nameOnCard: string;
          request_id: string;
          isVerified: boolean;
        }) => {
          if (values.idDoc === kycDocsId.E_VERIFY_PAN) {
            setPanCardDetails((preValue) => {
              return {
                ...preValue,
                panNumber: values.idNumber,
                panNameOnCard: values.nameOnCard,
                panRequestId: values.request_id,
                isPanVerified: values.isVerified,
                isPanUploaded: values.isVerified ? true : false,
              };
            });
          } else if (values.idDoc === kycDocsId.VERIFY_AADHAR_CARD) {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                aadharNumber: values.idNumber,
                aadharNameOnCard: values.nameOnCard,
                isAadharDocVerified: values.isVerified,
                isAadharDocUploaded: values.isVerified ? true : false,
              };
            });
          } else if (values.idDoc === kycDocsId.PASSPORT_FRONT) {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                passportIdNumber: values.idNumber,
                passportNameOnCard: values.nameOnCard,
                isPassportDocVerified: values.isVerified,
                isPassportDocUploaded: values.isVerified ? true : false,
              };
            });
            mixpanel.track("Passport front verified")
          } else if (values.idDoc === kycDocsId.PASSPORT_BACK) {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                isPassportBackDocVerified: true,
              };
            });
          } else if (values.idDoc === kycDocsId.E_VERIFY_DL) {
            setDrivingLicenseDetails((preValue) => {
              return {
                ...preValue,
                dlNumber: values.idNumber,
                dlNameOnCard: values.nameOnCard,
                dlRequestId: values.request_id,
                isDlDocVerified: values.isVerified,
                isDLFrontUploaded: values.isVerified ? true : false,
              };
            });
          } else if (values.idDoc === kycDocsId.VERIFY_DL_DOC) {
            setDrivingLicenseDetails((preValue) => {
              return {
                ...preValue,
                isDLBackUploaded: values.isVerified ? true : false,
              };
            });
          } else if (values.idDoc === kycDocsId.E_VERIFY_VOTERID) {
            setVoterIDCardDetails((preValue) => {
              return {
                ...preValue,
                voterNumber: values.idNumber,
                voterNameOnCard: values.nameOnCard,
                voterRequestId: values.request_id,
                isVIDocVerified: values.isVerified,
                isVoterFrontUploaded: values.isVerified ? true : false,
              };
            });
          }
        },
      );
  };

  React.useEffect(() => {
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;

      getUserDocs(currentUser)
        .then((response) => {
          setKycDetails(response?.data);
          handlerCkycStatus()
            .then((ckycStatusValue) => {
              const isPanUploaded = response?.data?.userDocs?.filter(
                (value: { docName: string | string[] }) => value.docName.includes("PAN_CARD"),
              )?.length;
              isPanUploaded === 0 && setCkycStatus("");
              commonHandler(response, ckycStatusValue.data);
            })
            .catch(() => {
              commonHandler(response, "");
              setCkycMessage({
                successMsg: "",
                errorMsg: "Something Went Wrong!",
              });
              setCkycStatus("FAILED");
              setCKYCPhase(2);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleAadharBackUpload = (e: any) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    uploadAadharBack(data).then((res: any) => {
      mixpanel.track("Aadhar card uploaded_back");
      window.console.log(res);
      setAdharCardDetails({ ...adharCardDetails, isAadharBackUploaded: true });
    });
  };

  React.useEffect(() => {
    adharOTPValue.length === 6 && validateAadharOtp();
  }, [adharOTPValue]);

  const handleChangeUpload = (e: { target: { files: string | any[] } }, docType: string) => {
    window.console.log(e.target.files[0]);
    const data = new FormData();
    if (e.target.files.length === 1) {
      if (
        e.target.files[0].name.split(".")[1] === "pdf" ||
        e.target.files[0].name.split(".")[1] === "PDF"
      ) {
        setUploadedFileData({
          pdfFile: true,
          fileName: e.target.files[0].name,
        });
      } else {
        setUploadedFileData({
          pdfFile: false,
          fileName: e.target.files[0].name,
        });
      }

      const imagedata = e.target.files[0];
      data.append("file", imagedata);
      data.append("doc_type", docType);
      if (docType === "PAN_CARD") {
        setButtonLoader((prev) => ({ ...prev, panLoader: true }));
        setPanCardDetails((preValue) => {
          return { ...preValue, uploadedPanFile: URL.createObjectURL(e.target.files[0]) };
        });
        setIsDocScan(true);
      } else if (docType === "AADHAR_CARD") {
        setButtonLoader((prev) => ({ ...prev, aadhaarOtpLoader: true }));
        setAdharCardDetails((preValue) => {
          return { ...preValue, uploadedAdharFrontFile: URL.createObjectURL(e.target.files[0]) };
        });
        setIsDocScan(true);
      } else if (docType === "AADHAR_CARD_BACK") {
        setButtonLoader((prev) => ({ ...prev, aadhaarBackLoader: true }));
        setAdharCardDetails((preValue) => {
          return { ...preValue, uploadedAdharBackFile: URL.createObjectURL(e.target.files[0]) };
        });

        handleAadharBackUpload(e);
        // setIsDocScan(true);
      } else if (docType === "VOTER_CARD") {
        setVoterIDCardDetails((preValue) => {
          return {
            ...preValue,
            uploadedVoterFrontCardFile: URL.createObjectURL(e.target.files[0]),
          };
        });
        setButtonLoader((prev) => ({ ...prev, voterCardLoader: true }));
        setIsDocScan(true);
      } else if (docType === "DRIVING_LICENCE") {
        setDrivingLicenseDetails((preValue) => {
          return { ...preValue, uploadedDlFrontFile: URL.createObjectURL(e.target.files[0]) };
        });
        setIsDocScan(true);
        setButtonLoader((prev) => ({ ...prev, drivingLoader: true }));
        data.append("doc_dob", docDob);
      } else if (docType === "PASSPORT_BACK") {
        setPassportDetails((preValue) => {
          return { ...preValue, uploadedPassportBackFile: URL.createObjectURL(e.target.files[0]) };
        });
        setIsDocScan(true);
        setButtonLoader((prev) => ({ ...prev, passportBackLoader: true }));
      } else {
        setPassportDetails((preValue) => {
          return { ...preValue, uploadedPassportFrontFile: URL.createObjectURL(e.target.files[0]) };
        });
        setIsDocScan(true);
        setButtonLoader((prev) => ({ ...prev, passportFrontLoader: true }));
      }
    }

    setPanCardDetails((preValue) => {
      return {
        ...preValue,
        uploadedDocError: "",
      };
    });
    setAdharCardDetails((preValue) => {
      return {
        ...preValue,
        uploadedDocError: "",
      };
    });
    setDrivingLicenseDetails((preValue) => {
      return {
        ...preValue,
        uploadedDocError: "",
      };
    });
    setVoterIDCardDetails((preValue) => {
      return {
        ...preValue,
        uploadedDocError: "",
      };
    });
    setPassportDetails((preValue) => {
      return {
        ...preValue,
        uploadedFrontDocError: "",
        uploadedBackDocError: "",
      };
    });

    if (docType !== "AADHAR_CARD_BACK") {
      uploadDocuments(data)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            if (docType === "PAN_CARD") {
              mixpanel.track("Upload PAN");
              setPanCardDetails((preValue) => {
                return {
                  ...preValue,
                  isPanUploaded: true,
                  panNumber: response.data.idNumber,
                  panNameOnCard: response.data.nameOnCard,
                  panRequestId: response.data.request_id,
                };
              });

              setDocDob(response.data.doc_dob);
              setIsDocScan(false);
            } else if (docType === "AADHAR_CARD") {
              mixpanel.track("Aadhar card uploaded_front");
              setAdharCardDetails((preValue) => {
                return {
                  ...preValue,
                  isAadharDocUploaded: true,
                  aadharNumber: response.data.idNumber,
                  aadharNameOnCard: response.data.nameOnCard,
                };
              });
              setIsDocScan(false);
            } else if (docType === "VOTER_CARD") {
              mixpanel.track("VoterID  uploaded_front");
              setVoterIDCardDetails((preValue) => {
                return {
                  ...preValue,
                  isVoterFrontUploaded: true,
                  voterNumber: response.data.idNumber,
                  voterNameOnCard: response.data.nameOnCard,
                  voterRequestId: response.data.request_id,
                };
              });

              setIsDocScan(false);
            } else if (docType === "DRIVING_LICENCE") {
              mixpanel.track("DL uploaded_front");
              setDrivingLicenseDetails((preValue) => {
                return {
                  ...preValue,
                  isDLFrontUploaded: true,
                  dlNumber: response.data.idNumber,
                  dlNameOnCard: response.data.nameOnCard,
                  dlRequestId: response.data.request_id,
                  dlDob: response.data.doc_dob,
                };
              });
              setDocDob(response?.data.doc_dob);
              setIsDocScan(false);
            } else if (docType === "PASSPORT_BACK") {
              mixpanel.track("Passport uploaded_front");
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportBackUploaded: true,
                  passportIdNumber: response.data.idNumber,
                  passportAddress: response.data.address,
                  passportCity: response.data.city,
                  passportState: response.data.state,
                  passportDob: response.data.doc_dob,
                  passportPincode: response.data.pincode,
                };
              });
              setIsDocScan(false);
            } else {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportDocUploaded: true,
                  passportIdNumber: response.data.idNumber,
                  passportNameOnCard: response.data.nameOnCard,
                };
              });
              setIsDocScan(false);
            }
          }
        })
        .catch((reason) => {
          if (reason.response) {
            if (docType === "PAN_CARD") {
              setPanCardDetails((preValue) => {
                return {
                  ...preValue,
                  isPanUploaded: false,
                  uploadedDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            } else if (docType === "AADHAR_CARD") {
              setAdharCardDetails((preValue) => {
                return {
                  ...preValue,
                  isAadharDocUploaded: false,
                  uploadedDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            } else if (docType === "VOTER_CARD") {
              setVoterIDCardDetails((preValue) => {
                return {
                  ...preValue,
                  isVoterFrontUploaded: false,
                  uploadedDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            } else if (docType === "DRIVING_LICENCE") {
              setDrivingLicenseDetails((preValue) => {
                return {
                  ...preValue,
                  isDlFrontUploaded: false,
                  uploadedDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            } else if (docType === "PASSPORT_BACK") {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportBackUploaded: false,
                  uploadedBackDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            } else {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportDocUploaded: false,
                  uploadedFrontDocError: reason?.response?.data?.error?.type,
                };
              });
              setIsDocScan(false);
            }
          }
        })
        .finally(() =>
          setButtonLoader((prev) => ({
            ...prev,
            aadhaarOtpLoader: false,
            panLoader: false,
            aadhaarBackLoader: false,
            voterCardLoader: false,
            drivingLoader: false,
            passportBackLoader: false,
            passportFrontLoader: false,
          })),
        );
    }
  };

  const handleDlDobChangeHandler = (value: any) => {
    setDocDob(moment(value).format("DD/MM/YYYY").toString());
  };

  const handlePassportDobChangeHandler = (value: any) => {
    setPassportDetails((preValue) => {
      return { ...preValue, passportDob: moment(value).format("DD/MM/YYYY").toString() };
    });
    setDocDob(moment(value).format("DD/MM/YYYY").toString());
  };

  const handleEVerifyVoterCard = async () => {
    setIsDocScan(true);
    setLoading(true);
    mixpanel.track("VoterID  uploaded_back");
    const data = new FormData();

    await fetch(voterIDCardDetails.uploadedVoterFrontCardFile)
      .then((image) => image.blob())
      .then((blob) => {
        const file = new File([blob], uploadedFileData.fileName);
        data.append("file", file);
      });
    data.append("doc_type", docType);
    data.append("id_number", voterIDCardDetails.voterNumber);
    data.append("name_on_card", voterIDCardDetails.voterNameOnCard);
    data.append("request_id", voterIDCardDetails.voterRequestId);
    data.append("doc_dob", docDob);
    setVoterIDCardDetails((preValue) => {
      return {
        ...preValue,
        messageVIVerify: "",
      };
    });

    eVerifyVoterIdCard(data)
      .then(async (response) => {
        if (response.data.type === "success") {
          mixpanel.track("VoterID verified");
          const data = new FormData();
          await fetch(voterIDCardDetails.voterBackCardFile)
            .then((image) => image.blob())
            .then((blob) => {
              const file = new File([blob], voterIDCardDetails.VoterIdBackImageFileName);
              data.append("file", file);
            });
          data.append("doc_dob", docDob);
          data.append("doc_type", "VOTER_CARD_BACK");
          data.append("name_on_card", voterIDCardDetails.voterNameOnCard);
          data.append("request_id", voterIDCardDetails.voterRequestId);
          data.append("idNumber", voterIDCardDetails.voterNumber);
          data.append("doc_name", response?.data?.result?.docName);
          data.append("id", response?.data?.result?.id);

          verifyVoterIdCardDocument(data)
            .then(() => {
              setNumberOfVerifiedDoc(2);
              setVoterIDCardDetails((preValue) => {
                return {
                  ...preValue,
                  isVIDocVerified: true,
                };
              });
              setIsDocScan(false);
            })
            .catch(() => {
              setIsDocScan(false);
            });
        } else {
          setVoterIDCardDetails((preValue) => {
            return {
              ...preValue,
              messageVIVerify: response.data.message,
              isVIDocVerified: false,
            };
          });
          setIsDocScan(false);
        }
      })
      .catch((reason) => {
        if (reason.response) {
          setVoterIDCardDetails((preValue) => {
            return {
              ...preValue,
              messageVIVerify: reason?.response?.data?.error?.type,
            };
          });
          setIsDocScan(false);
        } else if (reason.request) {
          setVoterIDCardDetails((preValue) => {
            return {
              ...preValue,
              messageVIVerify: "Something Went Wrong, Slow Connection",
            };
          });
          setIsDocScan(false);
        } else {
          setVoterIDCardDetails((preValue) => {
            return {
              ...preValue,
              messageVIVerify: "Something Went Wrong, Please Refresh and Upload again",
            };
          });
          setIsDocScan(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEverifyDL = async () => {
    const data = new FormData();
    mixpanel.track("DL uploaded_back");
    setIsDocScan(true);
    setLoading(true);
    await fetch(drivingLicenseDetails.uploadedDlFrontFile)
      .then((image) => image.blob())
      .then((blob) => {
        const file = new File([blob], uploadedFileData.fileName);
        data.append("file", file);
      });
    data.append("doc_type", docType);
    data.append("id_number", drivingLicenseDetails.dlNumber);
    data.append("name_on_card", drivingLicenseDetails.dlNameOnCard);
    data.append("request_id", drivingLicenseDetails.dlRequestId);
    data.append("doc_dob", docDob);
    setDrivingLicenseDetails((preValue) => {
      return {
        ...preValue,
        messageDLVerified: "",
        errorMessageDLVerified: "",
      };
    });

    eVerifyDL(data)
      .then(async (response) => {
        if (response.data.type === "success") {
          mixpanel.track("DL Verified");
          const data = new FormData();
          await fetch(drivingLicenseDetails.dlBackFile)
            .then((image) => image.blob())
            .then((blob) => {
              const file = new File([blob], drivingLicenseDetails.dlBackImageFileName);
              data.append("file", file);
            });
          data.append("doc_dob", docDob);
          data.append("doc_type", "DRIVING_LICENCE_BACK");
          data.append("name_on_card", drivingLicenseDetails.dlNameOnCard);
          data.append("request_id", drivingLicenseDetails.dlRequestId);
          data.append("idNumber", drivingLicenseDetails.dlNumber);
          data.append("doc_name", response?.data?.result?.docName);
          data.append("id", response?.data?.result?.id);

          verifyDrivingLicenseDocument(data)
            .then((response) => {
              setNumberOfVerifiedDoc(2);
              setDrivingLicenseDetails((preValue) => {
                return {
                  ...preValue,
                  errorMessageDLVerified: "",
                  messageDLVerified: response.data.message,
                  isDlDocVerified: true,
                };
              });
              setIsDocScan(false);
              setTimeout(
                () =>
                  setDrivingLicenseDetails((preValue) => {
                    return { ...preValue, messageDLVerified: "" };
                  }),
                5000,
              );
            })
            .catch((err) => {
              setIsDocScan(false);
              setDrivingLicenseDetails((preValue) => {
                return {
                  ...preValue,
                  messageDLVerified: "",
                  errorMessageDLVerified: err.response.data.error.type,
                };
              });
            });
          setTimeout(
            () =>
              setDrivingLicenseDetails((preValue) => {
                return { ...preValue, messageDLVerified: "", errorMessageDLVerified: "" };
              }),
            5000,
          );
        } else {
          setDrivingLicenseDetails((preValue) => {
            return {
              ...preValue,
              messageDLVerified: "",
              errorMessageDLVerified: response.data.message,
              isDlDocVerified: true,
            };
          });
          setIsDocScan(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          setDrivingLicenseDetails((preValue) => {
            return {
              ...preValue,
              messageDLVerified: "",
              errorMessageDLVerified: err.response.data.error.type
                ? err.response.data.error.type
                : "Something Went Wrong, Please Refresh and Upload again",
            };
          });
          setIsDocScan(false);
        } else if (err.request) {
          setDrivingLicenseDetails((preValue) => {
            return {
              ...preValue,
              errorMessageDLVerified: "Something Went Wrong, Slow Connection",
            };
          });
          setIsDocScan(false);
        } else {
          setDrivingLicenseDetails((preValue) => {
            return {
              ...preValue,
              errorMessageDLVerified: "Something Went Wrong, Please Refresh and Upload again",
            };
          });
          setIsDocScan(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNoAadhaar = () => {
    setButtonLoader((prev) => ({ ...prev, aadhaarOtpLoader: true }));
    noAadharValidation()
      .then((response: any) => {
        if (response?.data) {
          handleVerifyAdharCard();
        }
      })
      .catch((error: any) => {
        if (error.response !== undefined) {
          setAdharOTPError(error.response.data.error.type + " " + "Retry Again");
        }
      })
      .finally(() => setButtonLoader((prev) => ({ ...prev, aadhaarOtpLoader: false })));
  };

  const handleEVerifyPanCard = async () => {
    setIsDocScan(true);
    setLoading(true);
    const data = new FormData();

    await fetch(panCardDetails.uploadedPanFile)
      .then((r) => r.blob())
      .then((blob) => {
        const file = new File([blob], uploadedFileData.fileName);
        data.append("file", file);
      });
    data.append("doc_type", docType);
    data.append("id_number", panCardDetails.panNumber);
    data.append("name_on_card", panCardDetails.panNameOnCard);
    data.append("request_id", panCardDetails.panRequestId);
    data.append("doc_dob", docDob);
    setPanCardDetails((preValue) => {
      return {
        ...preValue,
        messagePan: "",
      };
    });

    eVerifyPan(data)
      .then((response) => {
        const res = JSON.stringify(response.data);
        const obj = JSON.parse(res);
        if (response.data.type === "success") {
          mixpanel.track("PAN Verified");
          setNumberOfVerifiedDoc(1);
          setAadhaarLinked(response?.data?.aadhaarLinked);
          setPanCardDetails((preValue) => {
            return {
              ...preValue,
              isPanVerified: obj.resultDTO.isVerified,
              uploadedPanFile: "",
            };
          });
          setIsDocScan(false);
        } else {
          setPanCardDetails((preValue) => {
            return {
              ...preValue,
              isPanVerified: false,
              messagePan: response.data.message,
            };
          });
          setIsDocScan(false);
        }
        setTimeout(
          () =>
            setPanCardDetails((preValue) => {
              return {
                ...preValue,
                messagePan: "",
              };
            }),
          5000,
        );
      })
      .catch((err) => {
        if (err.response) {
          setPanCardDetails((preValue) => {
            return {
              ...preValue,
              messagePan: err.response.data.error.type,
            };
          });

          setIsDocScan(false);
        } else {
          setPanCardDetails((preValue) => {
            return {
              ...preValue,
              messagePan: "Something Went Wrong, Please Refresh and Upload again",
            };
          });

          setIsDocScan(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendAdharOtp = async () => {
    saveDateKey(dateKey + 1);
    saveDate(Date.now() + 180000);
    setIsDocScan(true);
    setLoading(true);
    const body = {
      aadharNo: adharCardDetails.aadharNumber,
      userId: currentUser.id,
    };

    try {
      sendAdhatOTP(body)
        .then((response) => {
          if (response?.data) {
            mixpanel.track("Aadhar OTP sent");
            setEntityId(response["data"]["entityId"]);
            setOtpReferenceNo(response["data"]["otpReferenceNo"]);
            setIsDocScan(false);
          } else {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: "Something went wrong",
              };
            }),
              setIsDocScan(false);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: "Something Went Wrong Upload Again",
              };
            }),
              setIsDocScan(false);
          } else {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: err.response.data.error.type,
              };
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setAdharCardDetails((preValue) => {
        return {
          ...preValue,
          messageAadhar: "something went wrong",
        };
      }),
        setIsDocScan(false);
      setLoading(false);
    }
  };

  const validateAadharOtp = async () => {
    setIsDocScan(true);
    setAdharCardDetails((preValue) => {
      return {
        ...preValue,
        messageOTP: "",
      };
    });
    const body = {
      otpReferenceNo: otpReferenceNo,
      entityId: entityId,
      aadharNo: adharCardDetails.aadharNumber,
      otp: adharOTPValue,
      userId: currentUser.id,
    };

    try {
      validateAdharOTP(body)
        .then((response) => {
          if (response?.data) {
            mixpanel.track("Aadhar OTP verified");
            saveAdharOTPValue("");
            handleVerifyAdharCard();
          } else {
            setAdharOTPError("Something went wrong");
            saveAdharOTPValue("");
            setIsDocScan(false);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            setAdharOTPError(err.response.data.error.type + " " + "Retry Again");
            saveAdharOTPValue("");
            setIsDocScan(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setAdharOTPError("Something Went Wrong" + " " + "retry Again");
      setIsDocScan(false);
      setLoading(false);
    }
  };

  const handleVerifyAdharCard = async () => {
    const data = new FormData();
    setIsDocScan(true);
    setAdharCardDetails((preValue) => {
      return {
        ...preValue,
        messageAadhar: "",
      };
    });
    data.append("doc_type", "AADHAR_CARD");
    data.append("id_number", adharCardDetails.aadharNumber);
    data.append("name_on_card", adharCardDetails.aadharNameOnCard);

    await fetch(adharCardDetails.uploadedAdharFrontFile)
      .then((image) => image.blob())
      .then((blob) => {
        const file = new File([blob], uploadedFileData.fileName);
        data.append("file", file);
      });

    try {
      verifyAadharCard(data)
        .then((response) => {
          if (response.data.type === "success") {
            mixpanel.track("Aadhar verified");
            setNumberOfVerifiedDoc(2);
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                isAadharDocVerified: true,
                uploadedAdharFrontFile: "",
              };
            });
            setIsDocScan(false);
          } else {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: response.data.message,
                isAadharDocVerified: false,
              };
            });
            setIsDocScan(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: err.response.data.error.type,
              };
            }),
              setIsDocScan(false);
          } else if (err.request) {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: "Something Went Wrong, Slow Connection maybe",
              };
            }),
              setIsDocScan(false);
          } else {
            setAdharCardDetails((preValue) => {
              return {
                ...preValue,
                messageAadhar: "Something Went Wrong, Please Refresh and Upload again",
              };
            }),
              setIsDocScan(false);
          }
        });
    } catch (error) {
      {
        console.error(error);
      }
    }
  };

  const handlePassportVerification = async () => {
    setIsDocScan(true);
    setLoading(true);
    const data = new FormData();
    mixpanel.track("Passport uploaded_back");
    data.append("doc_type", "PASSPORT_FRONT"); // @chintan Needs to do dynamic
    data.append("id_number", passportDetails.passportIdNumber);
    data.append("name_on_card", passportDetails.passportNameOnCard);

    await fetch(passportDetails.uploadedPassportFrontFile)
      .then((image) => image.blob())
      .then((blob) => {
        const file = new File([blob], uploadedFileData.fileName);
        data.append("file", file);
      });
    setPassportDetails((preValue) => {
      return {
        ...preValue,
        messagePassport: "",
      };
    });

    try {
      verifyPassportFront(data)
        .then((response) => {
          if (response.data.type === "success") {
            mixpanel.track("Passport verified");
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                isPassportDocVerified: true,
                uploadedPassportFrontFile: "",
                messagePassport: "",
              };
            });
            setIsDocScan(false);
          } else if (response.data.type !== "success") {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                messagePassport: response.data.message,
              };
            });
            setIsDocScan(false);
          } else {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                messagePassport: "Something Went Wrong, Slow Connection maybe",
              };
            });
            setIsDocScan(false);
          }
          setIsDocScan(false);
        })
        .catch((err) => {
          if (err.response) {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                messagePassport: err.response.data.error.type,
              };
            });
            setIsDocScan(false);
          } else if (err.request) {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                messagePassport: "Something Went Wrong, Slow Connection maybe",
              };
            });
            setIsDocScan(false);
          } else {
            setPassportDetails((preValue) => {
              return {
                ...preValue,
                messagePassport: "Something Went Wrong, Please Refresh and Upload again",
              };
            });
            setIsDocScan(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handlePassportBackVerification = async () => {
    setIsDocScan(true);
    setLoading(true);
    if (!passportDetails.passportBackIdNumber) {
      setIsDocScan(false);
      setPassportDetails((preValue) => {
        return { ...preValue, messageBackPassport: "Please enter valid file number" };
      });

      setTimeout(() => {
        setPassportDetails((preValue) => {
          return { ...preValue, messageBackPassport: "" };
        });
      }, 3000);
    } else {
      const data = new FormData();
      data.append("doc_type", "PASSPORT_BACK"); // @chintan Needs to do dynamic
      data.append("id_number", passportDetails.passportIdNumber);
      data.append("file_number", passportDetails.passportBackIdNumber);
      data.append("pincode", passportDetails.passportPincode);
      data.append("address", passportDetails.passportAddress);
      data.append("city", passportDetails.passportCity);
      data.append("state", passportDetails.passportState);
      data.append("doc_dob", passportDetails.passportDob);

      await fetch(passportDetails.uploadedPassportBackFile)
        .then((r) => r.blob())
        .then((blob) => {
          const file = new File([blob], uploadedFileData.fileName);
          data.append("file", file);
        });

      try {
        verifyPassportBack(data)
          .then((response) => {
            if (response.data.type === "success") {
              mixpanel.track("Passport Back verified");
              setNumberOfVerifiedDoc(2);
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportBackDocVerified: true,
                  uploadedPassportFrontFile: "",
                  messageBackPassport: "",
                };
              });
              setIsDocScan(false);
            } else {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  isPassportBackDocVerified: false,
                  messageBackPassport: response.data.message,
                };
              });
              setIsDocScan(false);
            }
          })
          .catch((reason) => {
            if (reason.response) {
              setPassportDetails((preValue) => {
                return { ...preValue, messageBackPassport: reason?.response?.data?.error?.type };
              });

              setIsDocScan(false);
            } else if (reason.request) {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  messageBackPassport: "Something Went Wrong, Slow Connection maybe",
                };
              });

              setIsDocScan(false);
            } else {
              setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  messageBackPassport: "Something Went Wrong, Please Refresh and Upload again",
                };
              });
              setIsDocScan(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (reason) {
        {
          setLoading(false);
          console.error(reason);
        }
      }
    }
  };

  const handleUploadAdditionalDocs = async (
    e: { target: { files: any[] } },
    code: string,
    purpose: string,
    id: number,
  ) => {
    setIsDocScan(true);
    const fileData = e.target.files[0];
    const data = new FormData();
    data.append("file", fileData);

    if (
      e.target.files[0].name.split(".")[1] === "pdf" ||
      e.target.files[0].name.split(".")[1] === "PDF"
    ) {
      setUploadedFileData({
        pdfFile: true,
        fileName: e.target.files[0].name,
      });
    } else {
      setUploadedFileData({
        pdfFile: false,
        fileName: e.target.files[0].name,
      });
    }

    const findIndexOfDoc = (element: { [x: string]: any }) => element["code"] == code;
    try {
      setButtonLoader((prev) => ({ ...prev, [code && code as keyof typeof buttonLoader]: true }));
      setUploadDocumentError("");
      uploadAdditionalDocs({
        body: data,
        code: code,
        purpose: purpose,
      })
        .then((response: any) => {
          if (code === "Student-Passport") {
            setAdditionalDocUploaded({
              ...uploaded,
              studentPassport: true,
            });
          } else if (code === "Student-Passport Back") {
            setAdditionalDocUploaded({
              ...uploaded,
              studentPassportBack: true,
            });
          } else if (code === "University-Offer-Letter") {
            setAdditionalDocUploaded({
              ...uploaded,
              offerLetter: true,
            });
          } else if (code === "Rent-Agreement-Invoice") {
            setAdditionalDocUploaded({
              ...uploaded,
              rentNotice: true,
            });
          } else if (code === "Relationship-Proof") {
            setAdditionalDocUploaded({
              ...uploaded,
              relationProof: true,
            });
          } else if (code === "Beneficiary-ID") {
            setAdditionalDocUploaded({
              ...uploaded,
              beneficiaryId: true,
            });
          } else if (code === "Recipient-passport-Driving-license-SSN-number") {
            setAdditionalDocUploaded({
              ...uploaded,
              recipientPassDLssn: true,
            });
          } else if (code === "Gift-Declaration") {
            setAdditionalDocUploaded({
              ...uploaded,
              giftDeclaration: true,
            });
          } else if (code === "Air-Ticket-Copy") {
            setAdditionalDocUploaded({
              ...uploaded,
              airTicket: true,
            });
          } else if (code === "Visa") {
            setAdditionalDocUploaded({
              ...uploaded,
              visa: true,
            });
          } else if (code === "Loan-Sanction-letter") {
            setAdditionalDocUploaded({
              ...uploaded,
              loanLetter: true,
            });
          }
          if (response?.success) {
            mixpanel.track("Additional Doc Uploaded", {
              "Doc Name": data,
            });
            // const updatedTransactionLeadIds = [
            //   { ...transactionLeadIds, [code]: response.data.leadDocId },
            // ];
            setLeadIds({ ...leadIds, [code]: response.data.leadDocId });
            // setTransactionLeadIds(updatedTransactionLeadIds);
            setIsDocScan(false);
            if (code === "Loan-Sanction-letter") {
              const newLoanSanctionLetter = {
                ...loanSanctionLetterData,
                isUploaded: true,
                fileName: fileData.name,
                uploadedId: response.data.leadDocId,
                uploadedFile: URL.createObjectURL(e.target.files[0]),
                errorMessage: "",
              };
              setLoanSanctionLetterData(newLoanSanctionLetter);
            } else {
              const tempData = additionalDocData.filter(
                (data) => data.name === purposeValue,
              )[0];

              const uploadedData = listOfDocuments?.map((doc: any, index: number) =>
                index === id
                  ? {
                    ...doc,
                    isUploaded: true,
                    fileName: fileData.name,
                    uploadedId: response.data.leadDocId,
                    uploadedFile: URL.createObjectURL(e.target.files[0]),
                  }
                  : doc,
              );
              setListOfDocuments(uploadedData);

              // Clone the tempData object and its nested properties
              const clonedTempData = {
                ...tempData,
                uploadDoc: tempData.uploadDoc.map((doc, idx) =>
                  idx === tempData.uploadDoc.findIndex(findIndexOfDoc)
                    ? {
                      ...doc,
                      isUploaded: true,
                      fileName: fileData.name,
                      uploadedId: response.data.leadDocId,
                      uploadedFile: URL.createObjectURL(e.target.files[0]),
                    }
                    : doc,
                ),
              };

              if (
                code === "Recipient-passport-Driving-license-SSN-number" ||
                code === "Gift-Declaration"
              ) {
                clonedTempData.isUploaded = true;
              } else {
                clonedTempData.isUploaded = clonedTempData.uploadDoc.every(
                  (data) => data.isUploaded,
                );
              }

              const newDocData = additionalDocData.filter(
                (data) => data.name !== purposeValue,
              );

              setAdditionalDocData([...newDocData, clonedTempData]);
            }
          } else if (response?.response?.data?.success === false) {
            if (code === "Loan-Sanction-letter") {
              const loanSanctionLetter = {
                ...loanSanctionLetterData,
                isUploaded: true,
                fileName: fileData.name,
                uploadedFile: URL.createObjectURL(e.target.files[0]),
                errorMessage: response?.response?.data?.error?.type,
              };
              setLoanSanctionLetterData(loanSanctionLetter);
            } else {
              const filteredData = additionalDocData.filter(
                (data) => data.name === purposeValue,
              )[0];
              const findUploadDocindex = filteredData["uploadDoc"].findIndex(findIndexOfDoc);

              filteredData.uploadDoc[findUploadDocindex].isUploaded = true;
              filteredData.uploadDoc[findUploadDocindex].fileName = fileData.name;
              // filteredData.uploadDoc[findUploadDocindex].errorMessage =
              //   response?.response?.data?.error?.type;
              const finalDocData = additionalDocData.filter(
                (data) => data.name !== purposeValue,
              );
              setAdditionalDocData([...finalDocData, filteredData]);
            }
          }
          setIsDocScan(false);
        })
        .catch((error) => {
          window.console.log("ERRR", error);
          setUploadDocumentError(error?.response?.data?.error?.type);
          setIsDocScan(false);
          console.error(error);
        })
        .finally(() => {
          setButtonLoader((prev) => ({
            ...prev,
            [code && code as keyof typeof buttonLoader]: false
          }))
        })
    } catch (error) {
      {
        window.console.log("ERROR", error);
        setIsDocScan(false);
        console.error(error);
      }
    };
  };

  const handleAdditionalDocsSubmit = () => {
    getUserDocs(currentUser)
      .then((response) => {
        mixpanel.track("Confirm_additional_details");
        if (response?.data?.isDocVerified) {
          // navigate("/pick-recipient");
          navigate("/review");
        }
        if (state?.from === "send-money" && !kycDetailsData?.isDocVerified) {
          navigate("/pick-recipient");
        }
        if (transactionDetailsData?.purposeCode && response?.data?.isDocVerified) {
          navigate("/review");
        } else {
          setIsModalOpen(state?.showKycSidePopUp === false ? "" : "UnverifiedKYC");
        }
      })
      .catch((error) => {
        {
          console.log("response?.data?.isDocVerifiederror", error);
          console.error(error);
        }
      });
  };

  const getColor = (value: string) => {
    switch (value) {
      case "AADHAR_CARD":
        return adharCardDetails.isAadharDocVerified;
      case "DRIVING_LICENCE":
        return drivingLicenseDetails.isDlDocVerified;
      case "INDIAN_PASSPORT":
        return passportDetails.isPassportDocVerified && passportDetails?.isPassportBackDocVerified;
      case "VOTER_CARD":
        return voterIDCardDetails.isVIDocVerified;
      default:
        return false;
    }
  };

  const handlePreviewUploadedDoc = (docType: string) => {
    setIsModalOpen("preViewUploadedDoc");

    switch (docType) {
      case "PAN_CARD":
        return setPreViewImageSrc(panCardDetails.uploadedPanFile);
      case "AADHAR_CARD":
        return setPreViewImageSrc(adharCardDetails.uploadedAdharFrontFile);
      case "AADHAR_CARD_BACK":
        return setPreViewImageSrc(adharCardDetails.uploadedAdharBackFile);
      case "DRIVING_LICENCE_FRONT":
        return setPreViewImageSrc(drivingLicenseDetails.uploadedDlFrontFile);
      case "DRIVING_LICENCE_BACK":
        return setPreViewImageSrc(drivingLicenseDetails.dlBackFile);
      case "INDIAN_PASSPORT_FRONT":
        return setPreViewImageSrc(passportDetails.uploadedPassportFrontFile);
      case "INDIAN_PASSPORT_BACK":
        return setPreViewImageSrc(passportDetails.uploadedPassportBackFile);
      case "VOTER_CARD_FRONT":
        return setPreViewImageSrc(voterIDCardDetails.uploadedVoterFrontCardFile);
      case "VOTER_CARD_BACK":
        return setPreViewImageSrc(voterIDCardDetails.voterBackCardFile);
      default:
        return "";
    }
  };

  console.log("docType", docType);

  const handleDeleteDoc = () => {
    return docType === "PAN_CARD"
      ? setPanCardDetails({
        panNumber: "",
        panNameOnCard: "",
        panRequestId: "",
        isPanVerified: false,
        isPanUploaded: false,
        uploadedPanFile: "",
        messagePan: "",
        uploadedDocError: "",
      })
      : docType === "AADHAR_CARD"
        ? setAdharCardDetails({
          aadharNumber: "",
          aadharNameOnCard: "",
          isAadharDocVerified: false,
          isAadharDocUploaded: false,
          uploadedAdharFrontFile: "",
          messageAadhar: "",
          uploadedDocError: "",
          isAadharBackUploaded: false,
          uploadedAdharBackFile: "",
          uploadedBackError: "",
        })
        : docType === "DRIVING_LICENCE"
          ? drivingLicenseDetails.isDLBackUploaded
            ? setDrivingLicenseDetails((preValue) => {
              return {
                ...preValue,
                dlBackFile: "",
                isDLBackUploaded: false,
                dlBackImageFileName: "",
                messageDLVerified: "",
                errorMessageDLVerified: "",
                uploadedDocError: "",
              };
            })
            : setDrivingLicenseDetails((preValue) => {
              return {
                ...preValue,
                isDlDocVerified: false,
                isDLFrontUploaded: false,
                uploadedDlFrontFile: "",
                messageDLVerified: "",
                errorMessageDLVerified: "",
                uploadedDocError: "",
                dlNumber: "",
                dlNameOnCard: "",
                dlRequestId: "",
              };
            })
          : docType === "INDIAN_PASSPORT"
            ? passportDetails.isPassportBackUploaded
              ? setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  passportBackIdNumber: "",
                  isPassportBackDocVerified: false,
                  isPassportBackUploaded: false,
                  uploadedPassportBackFile: "",
                  messageBackPassport: "",
                  uploadedBackDocError: "",
                  passportAddress: "",
                  passportCity: "",
                  passportState: "",
                  passportDob: "",
                  passportPincode: "",
                };
              })
              : setPassportDetails((preValue) => {
                return {
                  ...preValue,
                  passportIdNumber: "",
                  passportNameOnCard: "",
                  uploadedPassportFrontFile: "",
                  isPassportDocVerified: false,
                  isPassportDocUploaded: false,
                  messagePassport: "",
                  uploadedFrontDocError: "",
                };
              })
            : docType === "VOTER_CARD" && voterIDCardDetails.isVoterBackUploaded
              ? setVoterIDCardDetails((preValue) => {
                return {
                  ...preValue,
                  voterBackCardFile: "",
                  isVoterBackUploaded: false,
                  VoterIdBackImageFileName: "",
                  messageVIVerify: "",
                  uploadedDocError: "",
                };
              })
              : setVoterIDCardDetails((preValue) => {
                return {
                  ...preValue,
                  voterNumber: "",
                  voterNameOnCard: "",
                  voterRequestId: "",
                  isVIDocVerified: false,
                  isVoterFrontUploaded: false,
                  uploadedVoterFrontCardFile: "",
                  messageVIVerify: "",
                  uploadedDocError: "",
                };
              });
  };

  const handleAdditionalDocRemove = (index: number, docType: string, code: any) => {
    const updatedListOfDocs = listOfDocuments.map((file: any) => {
      return file.id === index
        ? { ...file, isUploaded: false, uploadedFile: "", fileName: "" }
        : file;
    });
    setListOfDocuments(updatedListOfDocs);
    setAdditionalDocsUpload(updatedListOfDocs);
  };

  const verifyCKYCHandler = async () => {
    try {
      searchCkyc(userData?.id)
        .then((response) => {
          mixpanel.track("CKYC verified");
          setCKYCPhase(2);
          setCkycData(response?.data);
          setCkycMessage({
            successMsg: response?.data?.active ? "Congratulations! CKYC Id  Found" : "",
            errorMsg: response?.data?.errorMessage,
          });
          response?.data?.errorMessage !== "" &&
            response?.data?.errorMessage !== null &&
            setCkycStatus("FAILED");
        })
        .catch((err) => {
          err?.response?.data?.error?.type !== "" &&
            err?.response?.data?.error?.type !== null &&
            setCkycStatus("FAILED");
          setCKYCPhase(2);
          setCkycMessage({ successMsg: "", errorMsg: err?.response?.data?.error?.type });
          throw err;
        });
    } catch (error) {
      {
        setCkycStatus("FAILED");
        setCKYCPhase(2);
        console.error(error);
      }
    }
  };

  const downloadCkycHandler = async () => {
    try {
      downloadCKYC(ckycData?.ckycNumber, userData?.id)
        .then((response) => {
          setCkycData({
            ...ckycData,
            ckycEmailAdd: response?.data?.ckycEmailAdd,
            ckycMobileNumber: response?.data?.ckycMobileNumber,
          });
          setCkycMessage({
            successMsg: response?.data?.active ? "Congratulations! CKYC details downloaded" : "",
            errorMsg: response?.data?.errorMessage,
          });
          setCKYCPhase(3);
          response?.data?.errorMessage !== "" &&
            response?.data?.errorMessage !== null &&
            setCkycStatus("FAILED");
        })
        .catch((err) => {
          setCkycMessage({ successMsg: "", errorMsg: err?.data?.type });
        });
    } catch (error) {
      {
        console.error(error);
      }
    }
  };

  const sendCkycOtpHandler = async () => {
    saveDateKey(dateKey + 1);
    saveDate(Date.now() + 60000);
    try {
      sendCkycOtp(ckycData?.ckycNumber, userData?.id)
        .then(() => {
          mixpanel.track("CKYC_OTP_sent");
          setCKYCPhase(4);
        })
        .catch((err) => {
          /* eslint-disable-next-line */
          console.log(err);
        });
    } catch (error) {
      {
        console.error(error);
      }
    }
  };

  const validateCkycOtpHandler = async () => {
    const body = {
      vendorUserId: userData?.id,
      ckycNumber: ckycData?.ckycNumber,
      otp: Number(ckycAdharOTPValue),
    };
    try {
      validateCkycOtp(body)
        .then((response) => {
          if (response.data.isVerified) {
            setNumberOfVerifiedDoc(2);
            setCkycStatus("COMPLETED");
            setCkycData({
              ...ckycData,
              ckycOtpMessage: "",
            });
          }
          setCKYCPhase(4);
        })
        .catch((err) => {
          setCkycData({
            ...ckycData,
            ckycOtpMessage: err?.response?.data?.error?.type,
          });
        });
    } catch (error) {
      {
        console.error(error);
      }
    }
  };

  const preViewModalButton = (
    <Button
      id="9090sda88bjxa0jh-ksaj"
      bgColor="bg-secondary-600"
      fontStyle={{
        fontFamily: "",
        fontSize: "text-sm",
        fontWeight: "font-medium ",
        fontColor: "text-white",
      }}
      borderStyle={{
        borderRadius: "rounded",
      }}
      className="w-full px-10 py-2"
      onClickHandler={handleDeleteDoc}
    >
      <div className="mr-1">
        <ReloadIcon />
      </div>
      Re-upload
    </Button>
  );

  const preViewModalHeader = (
    <div className="flex items-center justify-start">
      <div
        className="flex items-center justify-between mr-4 lg:hidden"
        onClick={() => setIsModalOpen("")}
      >
        <LeftArrowIcon />
      </div>
      <div className="items-center justify-between hidden mr-10 lg:flex">
        <Button
          id="hjdjaj-43njds-bj"
          bgColor="bg-primary-450"
          fontStyle={{
            fontFamily: "",
            fontSize: "text-sm",
            fontWeight: "font-medium",
            fontColor: "text-primary",
          }}
          borderStyle={{
            borderRadius: "rounded",
          }}
          className="py-2 px-[18px]"
          onClickHandler={() => setIsModalOpen("")}
        >
          {"<< Go Back"}
        </Button>
      </div>
      <Typograpy
        tagType="div"
        text="Document Preview"
        className="text-black font-normal font-lato text-[32px] mr-[26px] hidden lg:flex"
      />
      <Typograpy
        tagType="div"
        text={uploadedFileData.fileName}
        className="text-2xl font-normal text-primary-400 font-lato"
      />
    </div>
  );

  return (
    <motion.div
      className="flex flex-col flex-1 lg:mt-12"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <NavbarAndStepperSection
        stepperSteps={kycDetailsData?.isDocVerified ? stepperSteps : kycStepperSteps}
      />
      <AdditionalDocumentsSection
        selectedPurpose={selectedPurpose}
        kycDetailsData={kycDetailsData}
        setIsModalOpen={setIsModalOpen}
        additionalDocData={listOfDocuments}
        kycVerificationAgreementNote={""}
        setSelectedPurpose={setSelectedPurpose}
        setDocType={setDocType}
        isSaveAdditionalDocuments={isSaveAdditionalDocuments}
        setIsSaveAdditionalDocuments={setIsSaveAdditionalDocuments}
        showInfoTip={showInfoTip}
        setShowInfoTip={setShowInfoTip}
        handleUploadAdditionalDocs={handleUploadAdditionalDocs}
        handleAdditionalDocsSubmit={handleAdditionalDocsSubmit}
        handleAdditionalDocRemove={handleAdditionalDocRemove}
        isLoanSanctionLetter={transactionDetailsData?.isEducation}
        loanSanctionLetterData={loanSanctionLetterData}
        setPreViewImageSrc={setPreViewImageSrc}
        isMobileDocumentListOpen={isMobileDocumentListOpen}
        isAgree={isAgree}
        numberOfVerifiedDoc={numberOfVerifiedDoc}
        upload={uploaded}
        buttonLoader={buttonLoader}
        uploadDocumentError={uploadDocumentError}
      />
      {!kycDetailsData?.isDocVerified && isMobileDocumentListOpen && (
        <div className="px-5 lg:hidden">
          <div
            className={clsx(
              docType === "" ? "flex" : "lg:hidden",
              "text-lg font-bold justify-start text-black-200 mb-4",
            )}
          >
            Upload document for verification
          </div>
          <div className="px-3 bg-white rounded pb-7 pt-9">
            <DocumentsListSection
              docType={docType}
              panCardDetails={panCardDetails}
              setDocType={setDocType}
              verifiedDocumentsData={verifiedDocumentsData}
              getColor={getColor}
              setIsModalOpen={setIsModalOpen}
              ckycStatus={ckycStatus}
            />
          </div>
          {numberOfVerifiedDoc > 0 && (
            <div
              className={clsx(
                docType === "" ? "flex" : "lg:hidden",
                numberOfVerifiedDoc === 1 ? "text-secondary-1000" : "text-success-150",
                "text-lg font-bold justify-center text-center mb-[102px] mt-4",
              )}
            >
              {numberOfVerifiedDoc === 1 ? "Select Any one" : "Completed"}
            </div>
          )}
          <div className={clsx("lg:hidden items-center justify-center w-full my-8")}>
            <Button
              id="dghdwgu0djas-dajkj"
              bgColor={numberOfVerifiedDoc > 1 ? "bg-primary" : "bg-grey-550"}
              fontStyle={{
                fontFamily: "",
                fontSize: "text-lg",
                fontWeight: "font-bold",
                fontColor: "text-white",
              }}
              borderStyle={{
                borderWidth: "border-none",
                borderRadius: "rounded-[75px]",
              }}
              className="w-10/12 py-3 m-[auto]"
              onClickHandler={() => {
                setIsModalOpen("KYCVerified"),
                  setIsMobileDocumentListOpen(false),
                  panCardDetails?.isPanUploaded && mixpanel.track("Submit 1 of 2")
              }}
              disabled={numberOfVerifiedDoc !== 2}
            >
              {numberOfVerifiedDoc === 0
                ? "Submit 0 of 2"
                : numberOfVerifiedDoc === 2
                  ? "Submit 2 of 2"
                  : panCardDetails?.isPanUploaded && "Submit 1 of 2"}
            </Button>
          </div>
        </div>
      )}
      <KycModals
        kycDetailsData={kycDetailsData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        state={state}
        docType={docType}
        numberOfVerifiedDoc={numberOfVerifiedDoc}
        panCardDetails={panCardDetails}
        setDocType={setDocType}
        verifiedDocumentsData={verifiedDocumentsData}
        getColor={getColor}
        handlePreviewUploadedDoc={handlePreviewUploadedDoc}
        handleDeleteDoc={handleDeleteDoc}
        handleChangeUpload={handleChangeUpload}
        handleEVerifyPanCard={handleEVerifyPanCard}
        adharCardDetails={adharCardDetails}
        setDrivingLicenseDetails={setDrivingLicenseDetails}
        sendAdharOtp={sendAdharOtp}
        handleNoAadhaar={handleNoAadhaar}
        buttonLoader={buttonLoader}
        entityId={entityId}
        dateKey={dateKey}
        date={date}
        saveAdharOTPValue={saveAdharOTPValue}
        adharOTPError={adharOTPError}
        drivingLicenseDetails={drivingLicenseDetails}
        handleEverifyDL={handleEverifyDL}
        passportDetails={passportDetails}
        handlePassportBackVerification={handlePassportBackVerification}
        handlePassportVerification={handlePassportVerification}
        voterIDCardDetails={voterIDCardDetails}
        setVoterIDCardDetails={setVoterIDCardDetails}
        handleEVerifyVoterCard={handleEVerifyVoterCard}
        kycVerificationAgreementNote={""}
        preViewModalHeader={preViewModalHeader}
        preViewModalButton={preViewModalButton}
        preViewImageSrc={preViewImageSrc}
        isAgree={isAgree}
        setIsAgree={setIsAgree}
        isPDFfile={uploadedFileData.pdfFile}
        selectedPurpose={selectedPurpose}
        listOfPurposeforAdditionalDocuments={listOfPurposeforAdditionalDocuments}
        setPanCardDetails={setPanCardDetails}
        setIsMobileDocumentListOpen={setIsMobileDocumentListOpen}
        docDob={docDob}
        handleDlDobChangeHandler={handleDlDobChangeHandler}
        setPassportDetails={setPassportDetails}
        handlePassportDobChangeHandler={handlePassportDobChangeHandler}
        ckycPhase={ckycPhase}
        verifyCKYCHandler={verifyCKYCHandler}
        downloadCkycHandler={downloadCkycHandler}
        sendCkycOtpHandler={sendCkycOtpHandler}
        saveCkycAdharOTPValue={saveCkycAdharOTPValue}
        ckycMessage={ckycMessage}
        ckycStatus={ckycStatus}
        ckycData={ckycData}
        validateCkycOtpHandler={validateCkycOtpHandler}
        aadhaarLinked={aadhaarLinked}
        loading={loading}
      />
      {isDocScan && (
        <div className="fixed z-50 flex-1 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
          {View}
        </div>
      )}
    </motion.div>
  );
};

export default UserDocuments;
