import { removeState } from "../persistence/persistence";

export const clearTransactionStates = (keysToRemove: string[]): void => {
  try {
    keysToRemove.forEach((key) => {
      removeState(key);
      window.location.reload();
    });
  } catch (err) {
    console.error("Error clearing transaction states", err);
  }
};
