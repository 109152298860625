/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { jwtDecode } from "jwt-decode";
import Button from "../../../components/ui/Button";
import { getErrorMessage, regexForEmailAddress, regexForPassword } from "../../../utils";
import { handleLogin, initialXSRFToken, verifyReCaptcha } from "../../../services/auth";
import ForgotPassword from "../ForgotPassword";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isFirstTimeUser, isSignupSuccess } from "../../../store";
import { AnimatedSwipeLeftButton } from "../../../components/ui/AnimatedSwipeLeftButton";
import mixpanel from "mixpanel-browser";
import { getDetails } from "../../../services/payments";
import LoginSuccessFullLoader from "./LoginSuccessFullLoader";
import NewLoginLaptop from "../../../assets/Icons/NewLoginLaptop";
import { LoginAnimation } from "../../../components/ui/LoginAnimation";
import { useSpring, animated, useChain, useSpringRef } from "@react-spring/web";
import ReCAPTCHA from "react-google-recaptcha";
import { Snackbar, Alert, AlertTitle, TextField } from "@mui/material";
import TermsModal from "../SignUp/TermsModal";
import ErrorField from "../../../components/ui/ErrorField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FooterView from "../../../components/ui/FooterView";
import CaptchaTest from "../../../components/ui/Captcha";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import { CloseIcon } from "../../../assets/Icons";
import MobileIconNew from "../../../assets/Icons/MobileIconNew";
import { keysToRemove } from "../../../constants/keysToRemove";
import { clearTransactionStates } from "../../../services/transactionDetails/clearTransactionStatus";
import { appLinks } from "../../../constants";
import playStore from "../../../assets/images/playstore.webp";
import appStore from "../../../assets/images/appstore.webp";

const SignIn = () => {
  const navigate = useNavigate();
  const [isIncorrectPassword, setInorrectPassword] = React.useState<boolean>(false);
  const [isUserRegistered, setIsUserRegistered] = React.useState<string>("");
  const [isForgotPassword, setIsForgotPassword] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const setIsFirstTimeLogin = useSetRecoilState(isFirstTimeUser);
  const phoneNumber = sessionStorage.getItem("phoneNumber") || "";
  const isUseEffectFired = React.useRef(false);
  const recaptchaRef = React.useRef<any | null>(null);
  const [signinError, setSigninError] = React.useState<string>("");
  const signupSuccessfull = useRecoilValue(isSignupSuccess);
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState<boolean>();
  const [isTermsModalPrivacyOpen, setIsTermsModalPrivacyOpen] = React.useState<boolean>();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const IsShowCaptcha = process.env.REACT_APP_LOGIN_CAPTCHA;
  const [captchaValue, setCaptchaValue] = React.useState<any>("");
  const [loader, setLoader] = React.useState(false);
  const [mobileAppBanner, setMobileAppBanner] = useState(true);

  const location = useLocation();
  const userAgent = navigator.userAgent || navigator.vendor;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);

  const baseClasses =
    "flex items-center justify-center xs:mt-[-70%]  mt-5 lg:py-10 lg:w-5/12 xl:mt-5 2xl:mt-5 lg:mt-5 md:mt-5 sm:mt-5";
  const iosClass = "xs:mt-[150%]";

  const containerClass = isIOS ? `${baseClasses} ${iosClass}` : baseClasses;

  useEffect(() => {
    const emailCheck = sessionStorage.getItem("email");

    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      initialXSRFToken();

      if (emailCheck) {
        navigate('/', { replace: true });
      } else {
        navigate('/auth/sign-in', { replace: true });
      }
    }
  }, [navigate]);

  const doSubmit = (data: any) => {
    if (validateCaptcha(captchaValue)) {
      loadCaptchaEnginge(6);
      setCaptchaValue("");
      handleSubmitLogin(data);
    } else {
      console.error("Captcha Does Not Match");
      setSigninError("Captcha Does Not Match");
      setCaptchaValue("");
    }
  };

  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;

  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });

  const {
    formState: { errors },
    setValue,
    handleSubmit,
    control,
  }: any = useForm();

  useEffect(() => {
    if (location?.state?.myData) {
      const { email, password } = location?.state?.myData;
      setValue("email", email);
      setValue("password", password);
      triggerChange("email", email);
      triggerChange("password", password);
    }
  }, [setValue, location]);

  const triggerChange = (fieldName: any, value: any) => {
    const input = document.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
    if (input) {
      input.value = value;
      input.dispatchEvent(new Event("input", { bubbles: true }));
    }
  };

  const loginBanners = ["loginBanner1", "loginBanner2", "loginBanner3", "loginBanner4"];

  const bannerRef: any = useSpringRef();
  const loginRef: any = useSpringRef();

  const loginStyle = useSpring({
    from: {
      x: 620,
      // y: 0
    },
    delay: 1000,
    to: {
      x: 0,
      // y: -1000
    },
    ref: loginRef,
  });

  const bannerStyle = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    ref: bannerRef,
  });

  useChain([loginRef, bannerRef]);

  useEffect(() => {
    // clearTransactionStates(keysToRemove);
    if (!isUseEffectFired.current) {
      isUseEffectFired.current = true;
      initialXSRFToken();
    }
  }, []);

  const onSubmit = async (data: any) => {
    if (IsShowCaptcha !== "false") {
      if (captchaValue.length == 0) {
        setSigninError("Please Fill Captcha");
      } else {
        doSubmit(data);
      }
    } else {
      handleSubmitLogin(data);
    }
  };

  const handleSubmitLogin = async (data: any) => {
    try {
      const email = data.email;
      setIsLoading(true);
      handleLogin({ email: data.email, password: data.password })
        .then((response: any) => {
          setIsLoading(false);
          setSigninError(response?.response?.data?.Message);
          setTimeout(() => {
            setSigninError("");
          }, 3000);
          if (response?.data) {
            // window.console.log(response?.data);
            const decodedToken: any = jwtDecode(response.data.idToken);
            if (decodedToken.sub.toLowerCase() === data.email.toLowerCase()) {
              sessionStorage.setItem("email", email);
              sessionStorage.setItem("id", response.data.id);
              sessionStorage.setItem("idToken", response.data.idToken);
              sessionStorage.setItem("currentUser", JSON.stringify(response.data));
              if (response.data.login_count === 1) {
                setIsFirstTimeLogin(true);
              }
              const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
              getDetails(currentUser).then((response) => {
                const userName = response?.data?.firstName
                  ? response?.data?.firstName
                  : "" + " " + response?.data?.lastName
                    ? response?.data?.lastName
                    : "";
                response?.data?.phoneNumber && mixpanel.identify(response?.data?.phoneNumber);
                mixpanel.alias(response?.data?.phoneNumber);
                sessionStorage.setItem("phoneNumber", response?.data?.phoneNumber);
                mixpanel.register({
                  name: userName,
                  phone: response?.data?.phoneNumber,
                  email: response?.data?.email,
                });
                mixpanel.people.set({
                  $name: userName,
                  $email: response?.data?.email,
                  Email: response?.data?.email,
                  phone: response?.data?.phoneNumber,
                });
                mixpanel.track("Login_In_button", {
                  name: userName,
                  phone: response?.data?.phoneNumber,
                  email: response?.data?.email,
                });
              });
              navigate("/send-money");
              setIsUserRegistered(response.response.data.Message);
              if (
                response?.response?.data?.Message ===
                "User is not registered with Hop. Please register with us"
              ) {
                setIsUserRegistered(response?.response?.data?.Message);
                setInorrectPassword(false);
                recaptchaRef?.current?.reset();
              } else {
                setIsUserRegistered(response?.response?.data?.Message);
                setInorrectPassword(false);
                recaptchaRef?.current?.reset();
              }

              if (
                response?.response?.data?.Message ===
                "The user name and/or password are incorrect, Please try again" ||
                response?.response?.data?.Message ===
                "You've entered an incorrect password too many times, Please Try after 30 minutes."
              ) {
                setIsUserRegistered("");
                setInorrectPassword(true);
                setIsDisabled(true);
                recaptchaRef?.current?.reset();
              }
              setIsLoading(false);
            }
          }
        })
        .catch((error) => {
          console.error("Error", error?.response?.Message);
          setSigninError(error?.response?.Message);
          setIsLoading(false);
        });
    } catch (error) {
      // Handle error here
      console.error(error);
      alert("An error occurred while submitting the form");
    }
  };

  const handleClose = () => {
    console.log("hello");
    setMobileAppBanner(false);
  };
  // const captchaValidate = (token: string | null) => {
  //   if (token) {
  //     verifyReCaptcha({ response: token })
  //       .then((response: any) => {
  //         if (response.data) {
  //           setIsDisabled(false);
  //         } else {
  //           setIsDisabled(true);
  //         }
  //       })
  //       .catch(() => {
  //         setIsDisabled(true);
  //       });
  //   }
  // };

  return (
    <div className="relative top-0 h-screen bg-blue-500">
      {isLoading ? (
        <LoginSuccessFullLoader />
      ) : (
        <div
          style={{ zoom: "80%" }}
          className="top-0 left-0 flex flex-col items-center w-full h-full overflow-visible bg-gradient-to-r from-blue-500 to-blue-700 justify-evenly"
        >
          <div className="bottom-0 right-0 hidden cursor-default lg:absolute">
            <LoginAnimation />
          </div>
          <div className="top-0 flex lg:w-full absolute-mobile">
            <div className="w-full sm:pl-[67px] h-[140px] flex items-center">
              <img src={"/images/svg/whitelogo.svg"} alt="whitelogo" />
            </div>
          </div>

          <div className="flex flex-col items-center w-full bg-gradient-to-r from-blue-500 to-blue-700 lg:flex-row">
            <div id="left" className={containerClass}>
              <animated.div
                style={loginStyle}
                className="flex flex-col items-center p-16 px-8 mt-[500px] bg-white border-t-2 border-l-2 border-blue-800 rounded-md shadow-2xl lg:w-2/3 shadow-black-250 lg:mt-0"
              >
                <div className="hidden pt-[59px] text-2xl font-bold sm:flex pb-7 text-primary-200 font-lato">
                  Welcome Hopper!
                </div>
                <div className="flex flex-col items-center space-y-3 pb-[73px] sm:hidden">
                  <div className="text-xl font-bold pt-11 text-primary font-lato">HOPRemit</div>
                  <div className="text-base font-normal font-lato text-black-100">
                    Send money abroad anytime anywhere
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col items-center"
                  autoComplete="off"
                >
                  <div className="pb-[34px] sm:pb-[38px] w-screen sm:w-[400px] px-6 sm:px-0">
                    <Controller
                      control={control}
                      name="email"
                      rules={{ required: true, pattern: regexForEmailAddress }}
                      render={({ field: { name, value, onChange } }) => {
                        return (
                          <TextField
                            name={name}
                            id="email_signin"
                            type="email"
                            label="Email"
                            className={clsx(
                              errors.email || isUserRegistered !== "" ? "border" : "border-none",
                              `bg-primary-100`,
                            )}
                            value={value}
                            onChange={(v) => onChange(v)}
                            error={errors.email || isUserRegistered}
                            required
                            fullWidth
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col pb-[69px] sm:pb-[42px] w-screen px-6 sm:px-0 sm:w-[400px]">
                    <Controller
                      control={control}
                      name="password"
                      rules={{ required: true, pattern: regexForPassword }}
                      render={({ field: { name, value, onChange } }) => (
                        <div style={{ position: "relative" }}>
                          <TextField
                            name={name}
                            id="password_signin"
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            className={clsx(
                              errors.password || isIncorrectPassword ? "border" : "border-none ",
                              `bg-primary-100`,
                            )}
                            value={value}
                            onChange={onChange}
                            error={errors.password || isIncorrectPassword}
                            required
                            fullWidth
                          />
                          <IconButton
                            onClick={handleClickShowPassword}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </div>
                      )}
                    />
                    <ErrorField errorMessage={signinError} />

                    <div className="flex justify-end w-full text-sm font-normal sm:text-base pt-7 sm:pt-5 text-primary font-lato">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setIsForgotPassword(true);
                          mixpanel.track("forgot password");
                        }}
                      >
                        Forgot Password?
                      </span>
                    </div>
                  </div>
                  <div className="w-full">
                    {/* <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                      onChange={(e) => {
                        captchaValidate(e);
                      }}
                      ref={recaptchaRef}
                      size="invisible"
                      // theme="dark"
                    /> */}
                    <CaptchaTest captchaValue={captchaValue} setCaptchaValue={setCaptchaValue} />
                  </div>
                  <div className="mt-10">
                    <AnimatedSwipeLeftButton isLoading={isLoading} text="Log In" />
                  </div>
                </form>
                <div className="pt-7 sm:pt-6 pb-[55px] sm:pb-0 lg:mb-16">
                  <Button
                    id="signup-btn"
                    bgColor="hover:bg-transparent bg-primary"
                    fontStyle={{
                      fontFamily: "font-lato",
                      fontSize: "text-base",
                      fontWeight: "font-bold",
                      fontColor:
                        "text-white hover:text-primary transition-all hover:shadow-lg hover:shadow-primary shadow-lg",
                    }}
                    borderStyle={{
                      borderWidth: "border-2",
                      borderRadius: "rounded-full",
                      borderColor: "border-primary-200",
                    }}
                    className="w-[300px] h-[55px]"
                    onClickHandler={() => {
                      mixpanel.track("Login Page - Sign Up Button Clicked");
                      navigate("/auth/sign-up");
                    }}
                  >
                    Sign Up
                  </Button>
                </div>
                {signupSuccessfull === true ? (
                  <div
                    id="signupSuccessful"
                    className="px-5 py-3 font-sans text-green-700 bg-green-200 rounded-full"
                  >
                    Congratulations! You have signed up successfully.
                  </div>
                ) : null}
                {signinError && (
                  <Snackbar open={signinError !== ""} autoHideDuration={6000}>
                    <Alert severity="error" variant="filled">
                      <AlertTitle>Error</AlertTitle>
                      <strong>{signinError}</strong>
                    </Alert>
                  </Snackbar>
                )}
              </animated.div>
            </div>
            <animated.div
              style={bannerStyle}
              id="right"
              className="flex-col items-center justify-center hidden h-full lg:flex lg:w-7/12"
            >
              <div
                id="laptopSection"
                className="flex flex-row items-center w-full mt-10 md:mt-0 lg:pl-32"
              >
                <div className="flex items-center justify-center transition-all hover:scale-110">
                  <NewLoginLaptop />
                </div>
                <div className="flex flex-col w-full lg:ml-8 justify-evenly">
                  <span className="text-[24px] lg:text-[32px] text-white font-bold">HOPRemit</span>
                  <span className="text-[16px] lg:text-[20px] text-white lg:w-[650px]">
                    Now send money abroad in a digital, economical and seamless manner
                  </span>
                </div>
              </div>
              <div id="4banners" className="hidden w-full px-10 mt-10 lg:flex justify-evenly">
                {loginBanners.map((item, index) => {
                  return (
                    <div key={index} className="transition-all hover:scale-110">
                      <span className="">
                        <img src={`/images/png/${item}.png`} />
                      </span>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-center w-full mt-20">
                <div className="flex items-center justify-between w-4/5 text-opacity-100 bg-black border border-black rounded-lg bg-opacity-40">
                  <div className="flex flex-col items-center justify-center w-1/3 px-3 py-4 opacity-100 lg:px-10">
                    <span className="text-white font-bold text-[16px] lg:text-[32px] text-opacity-100">
                      {"₹2,500 Cr+"}
                    </span>
                    <span className="text-white font-semibold text-[13px] lg:text-[20px] text-opacity-100">
                      {"Remitted"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center w-1/3 px-3 py-4 border-white opacity-100 lg:px-10 border-x-2">
                    <span className="text-white font-bold text-[16px] lg:text-[32px] text-opacity-100">
                      {"60+"}
                    </span>
                    <span className="text-white font-semibold text-[13px] lg:text-[20px] text-opacity-100">
                      {"Countries Served"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center w-1/3 px-3 py-4 opacity-100 lg:px-10">
                    <span className="text-white font-bold text-[16px] lg:text-[32px] text-opacity-100">
                      {"40,000+"}
                    </span>
                    <span className="text-white font-semibold text-[13px] lg:text-[20px] text-opacity-100">
                      {"Happy Customers"}
                    </span>
                  </div>
                  <div className="flex flex-col items-center justify-center w-1/3 px-3 py-4 border-white opacity-100 lg:px-10 border-l-2">
                    <span className="text-white font-bold text-[16px] lg:text-[32px] text-opacity-100">
                      {"600+"}
                    </span>
                    <span className="text-white text-center font-semibold text-[13px] lg:text-[20px] text-opacity-100">
                      {"Universities Served"}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`flex flex-row items-center gap-9 mt-12`}>
                <div
                  className={`ml-[-3%] cursor-pointer`}
                  onClick={() => {
                    window.open(appLinks?.playSotre, "_blank");
                  }}

                >
                  <img src={playStore} width={249} height={82} alt="" />
                </div>
                <div
                  className={`ml-[-3%] cursor-pointer`}
                  onClick={() => {
                    window.open(appLinks?.appleStore, "_blank");
                  }}

                >
                  <img src={appStore} width={249} height={82} alt="" />
                </div>
              </div>
            </animated.div>
            <div className="sm:hidden">
              <FooterView showOffer={false} />
            </div>
          </div>
          {mobileAppBanner && (
            <div className="fixed top-0 bg-[#eaf5ff] z-50 w-full shadow-lg md:hidden">
              <div className="w-full relative">
                <div className="flex flex-row py-5 justify-center items-center px-4">
                  <MobileIconNew width="10%" height="10%" />
                  <div className="text-[16px] md:text-[20px] font-bold text-center md:text-left px-3">
                    Download the HOP Remit App
                  </div>
                </div>
                <div className="text-[14px] md:text-[16px] font-medium text-center">
                  Smart, swift & secure international money transfers
                </div>
                <div className="text-[14px] md:text-[16px] font-medium text-center">
                  with maximum savings and superior mobile user experience
                </div>
                <div className="flex flex-col content-center mt-4 items-center justify-center py-3">
                  <a
                    href="https://onelink.to/v7au6v?utm_source=nudge&utm_medium=signinhopremit&utm_campaign=appinstall"
                    className="bg-gradient-to-r from-[#50aefe] font-bold to-[#0c61f4] text-white py-2 px-12 md:px-28 text-sm md:text-lg rounded-xl shadow-[#50aefe] shadow-md hover:from-[#0c61f4] hover:to-[#50aefe] transition duration-300"
                  >
                    INSTALL NOW
                  </a>
                  <div className="text-[14px] md:text-[16px] font-bold text-black mt-2 text-center">
                    Get 100% OFF on Convenience Fees on App
                  </div>
                </div>
              </div>
              <div
                onClick={() => setMobileAppBanner(false)}
                className="absolute right-5 top-5 md:right-10 md:top-10 p-2 opacity-50"
              >
                <CloseIcon fill="black" />
              </div>
            </div>
          )}

          <div
            id="divider"
            className="hidden w-5/6 border-2 rounded-full lg:flex border-b-[#093EA7]"
          />
          <div
            id="footer"
            className="flex items-center justify-between w-full px-8 py-3 bg-gradient-to-r from-blue-500 to-blue-700"
          >
            <div id="left" className="flex items-center px-5 space-x-4 justify-evenly">
              <span
                onClick={() => setIsTermsModalOpen(true)}
                className="text-white text-[17px] cursor-pointer hover:scale-125 transition-all"
              >
                Terms & Conditions<span className="text-white mx-2 text-[17px]">|</span>Privacy
                Policy
              </span>
              {/* <span className="text-white text-[17px]">|</span>
              <span
                onClick={() => setIsTermsModalPrivacyOpen(true)}
                className="text-white text-[17px] cursor-pointer hover:scale-125 transition-all"
              >
                Privacy Policy
              </span> */}
            </div>
            <div id="right" className="text-white font-bold text-[14px] hidden lg:flex">
              Copyright by moneyhop.co ©
            </div>
          </div>
        </div>
      )}
      <ForgotPassword
        isForgotPassword={isForgotPassword}
        setIsForgotPassword={setIsForgotPassword}
      />
      <TermsModal
        isTermsModalOpen={isTermsModalOpen}
        setIsTermsModalOpen={setIsTermsModalOpen}
        modalTitle="Terms and Conditions"
      />
      <TermsModal
        isTermsModalOpen={isTermsModalPrivacyOpen}
        setIsTermsModalOpen={setIsTermsModalPrivacyOpen}
        modalTitle="Privacy Policy"
      />
    </div>
  );
};

export default SignIn;
